import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ProgressBar } from 'react-loader-spinner';
import './checkout.css';
import { HedingSubheding } from '../headings/heading';
import { ButtonPrimary, ButtonSecondary } from '../button/button';
import { customTitleCase } from '../pricingD2c/card/pricingD2C';


function CheckOutD2C(props) {
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const MID = searchParams.get("MID");
const [showInvoice, setShowInvoice] = useState(false);
const [error, setError] = useState(null);
const [invoiceData, setInvoiceData] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + 'api/confPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ MID }),
        });

        if (response.ok) {
          const data = await response.json();

          if (response.status === 200) {
            if(data.skip){
              window.location.href = data.link;
              return
            }
            setShowInvoice(true);
            setInvoiceData(data);
            localStorage.setItem('refreshPage', 'true');
          }
        } else {
          setError('Failed to process payment. Please try again later.');
          console.error('Request failed', response);
        }
      } catch (error) {
        setError('An error occurred while processing the payment. Please try again later.');
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [MID]);

  return (
    <>
      {error ? (
        <ErrorComponent message={error} />
      ) : (
        showInvoice ? (
          <B2Binvoice data={props.data} invoiceData={invoiceData} />
        ) : (
          <div className='body' style={{ justifyContent: 'center',marginTop:'0', alignItems: 'center',minHeight:'100%' }}>
            <HedingSubheding heading="Payment processing" sub_heading="Stay cool – payment processing underway. Hold tight, no refresh needed." />
            <ProgressBar
              height="80"
              width="80"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass="progress-bar-wrapper"
              borderColor='var(--jet-black)'
              barColor='var(--jet-black)'
            />
            {/* <HedingSubheding heading="" sub_heading="" />
            <HedingSubheding heading="" sub_heading="Please Don't Leave: you will automatically get redirected to the invoice" /> */}
          </div>
        )
      )}
    </>
  );
}

export const ErrorComponent = ({ message }) => (
  <div className='body' style={{padding:'20px',minHeight:'100%'}}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{margin:'auto',marginTop:'0',marginBottom:'-20px'}} width="40" height="40" viewBox="0 0 25 25" fill="none">
              <path d="M23.4562 19.3772L22.2495 18.1705V10.935H15.014L12.1365 8.0575H22.2495V4.66951H8.74855L7.54183 3.46283H23.4562V19.3772ZM9.89952 10.935H2.94252V19.1497H18.1143L9.89952 10.935ZM23.5815 24.617L19.321 20.3564H1.73584V3.46283H4.14921L5.35589 4.66951H2.94252V8.0575H7.02202L0.385254 1.42075L1.24619 0.559814L24.4424 23.756L23.5815 24.617Z" fill="#1C1B1F"/>
            </svg>
    <HedingSubheding heading="Payment Failed" sub_heading="Oops! Looks like your payment didn't go through. Please try again or check your payment method details."/>
    <ButtonPrimary direction={true} to={localStorage.getItem('lastPayment')} customStyle={{marginTop:'0'}} text={'Try Again'}/>
  </div>
);

// ... rest of your components remain the same


 const B2Binvoice = ({ data, invoiceData }) => {
  const redirected = invoiceData.redirectTo
  invoiceData = invoiceData.invoice
  function dateConvert(str){
    const date = new Date(str);

    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
  }
  function capitalizeWords(str) {
    const newStr = str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    return str.split(' ')[0].toUpperCase() + " " +str.split(' ')[1]
  }
  
  return (
    <div className='body' style={{padding:'25px',paddingTop:'0',minHeight:'100%'}}>
      <div className="check-out">
        <div className="headder">
          <div className="div">
            <div className="headding">Order confirmed!</div>
            <div className="trans-ID">Transaction ID: {invoiceData.mid}</div>
          </div> 
          <div className="date">{dateConvert(invoiceData.date)}</div>
        </div> 
        <div className="teact-wrap">
          <div className="heading">Thanks, {invoiceData.name}!</div>
          <p className="text">
            <span className="text-wrapper">
            The next step is crafting your personalized piece. Just head up to the abstract form, spill in our stories, add some visuals and our creative wizards craft one-of-a-kind edition for you.
            {/* <a href={invoiceData.abstract} style={{color:'#333332'}}> <u><strong> Click here to get started.</strong></u></a> */}
            </span>
          </p>
        </div>
        
        <NavLink to="/orders" className="cont">
            <div  className="img" />
            <div className='items'>
            {invoiceData.items.map((item, index) => (
            <div className='item'>
              <div className="div">
                <div className="heading-2">{"Imurs "+ (item.name)} </div>
                <p className="sub-heading" style={{textTransform:'none'}}>{customTitleCase(item.sub_headin) + " Edition"}</p>
              </div>
              <div className="price">₹{item.price}</div>
            </div>
            ))}
            </div>
        </NavLink>

        {/* <div className="final-price">
          ₹{invoiceData.items.reduce((total, item) => parseInt(total) + parseInt(item.price), 0)}
        </div> */}
        {invoiceData.abstract !== '#' && <div className='item div' style={{width:'100%',gap:'15px'}}>
        <ButtonSecondary direction={true} to={invoiceData.abstract.replace('abstract','tracking')} customStyle={{width:'100%',maxWidth:'100%',minWidth:'0'}} text="Track Order" />
        <ButtonPrimary direction={true} to={invoiceData.abstract} customStyle={{minWidth:'0'}} text="Fill Abstract Form" />
        </div>}
        
      </div>
    </div>
  );
};


export default CheckOutD2C;
