import React from "react";
import "./ordercardv2.css";
import ico from ".//defaultmag.jpg";
import { calculateBackgroundPropertiesTest } from "../../component/tracking/tracking";
import { NavLink } from "react-router-dom";
import { customTitleCase } from "../../component/pricingD2c/card/pricingD2C";
export const OrderCardV2 = (props) => {
    const formattedDate = dateConvert(props.order.createdAt);

    function dateConvert(str) {
        const date = new Date(str);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }
    setTimeout(() => {
        if (props.thumbnail) {
            console.log(`thumb_${props.order._id}`,props.customerName)
              const im = new Image();
              im.src = `https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${props.order.thumbnail}`;
              
              im.onload = function () {
                  const para = calculateBackgroundPropertiesTest(document.getElementById(`thumb_${props.order._id}`),im);
                  console.log(para);
                  document.getElementById(`thumb_${props.order._id}`).style.backgroundImage = `url(${im.src})`;
                  document.getElementById(`thumb_${props.order._id}`).style.backgroundSize = para.backgroundSize;
                  document.getElementById(`thumb_${props.order._id}`).style.backgroundPosition = para.backgroundPosition;
              };
      
            }
    }, 70);
    

    return (
        <div className="order-cardV2">
            <div className="image bg" id={`thumb_${props.order._id}`} style={{backgroundImage:`url('./defaultmag.jpg')`,backgroundSize:'auto 100%'}} />
            <div className="cont">
                <div className="text-wrap">
                    <div className="main-cont">
                        <div className="group">
                            <div className="overlap-group" style={{backgroundImage:`url('/logos/planSymbol/${props.name}.svg')`}}>
                            </div>
                        </div>
                        <p className="name">
                            <span className="text-wrapper">
                               {customTitleCase(props.btb?props.order.customerName:props.order.category.name)}
                                <br />
                            </span>
                            <span className="span"> {formattedDate}</span>
                        </p>
                    </div>
                    <div className="flex">
                        <div className="div">
                            <div className="text">Order Status</div>
                            <div className="status-cont">
                                <img className="icon" alt="Icon" src={`/logos/status/${props.img}.svg`} />
                                <div className="status">{props.status}</div>
                            </div>
                        </div>
                        <a href={props.ShareLinkB2B?`https://api.whatsapp.com/send?text=Hi, ${props.order.customerName ? props.order.customerName.split(' ')[0] + '.%20' : ''}Exciting%20news!%20As%20a%20token%20of%20gratitude,%20we%E2%80%99re%20crafting%20a%20personalized%20magazine%20capturing%20your%20unforgettable%20memories.%20%F0%9F%93%B8%E2%9C%A8%20Kindly%20share%20your%20cherished%20images%20and%20memories%20via%20this%20form%20https://iamyourstory.in/abstract/${props.order._id}`:`https://api.whatsapp.com/send?text=Hey,%20just%20got%20my%20${props.order.category.name.replace(/\b\w/g, match => match.toUpperCase()).replace('And','&')}%20Edition%20from%20imurs,%20it%E2%80%99s%20pure%20magic!%20Reliving%20all%20the%20best%20bits.%20Just%20wanted%20to%20share%20it%20with%20you%20%F0%9F%92%95%F0%9F%92%95%F0%9F%92%95%20%20https://iamyourstory.in/edition/${props.order._id}`}>
                        <div className="share">
                            <img className="img" alt="Share" src="share.svg" />
                        </div>
                        </a>
                    </div>
                </div>
                <NavLink className="button" to={props.link}>
                    <div className="text-2">{props.buttonstatus}</div>
                </NavLink>
            </div>
        </div>
    );
};
