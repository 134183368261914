import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ButtonSecondary } from "../button/button";
import "./navbar.css";

export const NavbarLanding = () => {
    const location = useLocation();
    const [currentPathname, setPath] = useState(location.pathname);
  
    useEffect(() => {
      // Update state when the pathname changes
      setPath(location.pathname);
    }, [location.pathname]);
   

    const [scrolling, setScrolling] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu open/close
    const [isMenuclosing, setIsMenuClosing] = useState(false); 

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.landingMain').addEventListener('scroll', handleScroll);
            if(currentPathname !=="/"){
                setScrolling(true)
                
            }
        return () => {
            document.querySelector('.landingMain').removeEventListener('scroll', handleScroll);
        };
        }, 200);
        
    }, [currentPathname]);

    const handleScroll = () => {
        if (document.querySelector('.landingMain').scrollTop > 4300 || window.location.pathname!=="/") {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle menu state
        setIsMenuClosing(false)
    };

    // Function to close the menu
    const closeMenu = () => {
        setIsMenuClosing(true);
        setTimeout(()=>{
            setIsMenuOpen(false);
        },1000)   
    };
    
    console.log(currentPathname)
   

    return (
        <div className={`nav ${scrolling ? 'scrolled' : ''}`}>

            <div className="menu" style={{display:'none'}}>
                <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={toggleMenu}>
                    <path d="M0 19.2211V17.4711H28V19.2211H0ZM0 10.875V9.12495H28V10.875H0ZM0 2.52881V0.778809H28V2.52881H0Z" fill="#333332"/>
                </svg>
           </div>

          { /* <i className='fa-solid fa-bars menu' ></i>*/}
            <div exact to="/" className="navTitle" style={{color:currentPathname==='/'?"var(--isabeline)":"var(--jet-black)"}}>
            <svg style={{opacity:`${(currentPathname!=='/') ? 1 : 0}`}} onClick={()=>{window.history.back()}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
                <NavLink to="/" style={{justifySelf:'center',color:'inherit',cursor:'pointer'}}>imurs</NavLink>
                <a href="https://wa.me/message/MAHV5U6PLWX3I1" style={{color:'inherit'}}> 
                 <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" style={{opacity:`${scrolling?'1':'0'}`}}>
  <g clip-path="url(#clip0_461_42949)">
    <path d="M0.547485 20L1.95332 14.8642C1.08582 13.3608 0.629985 11.6567 0.630819 9.90917C0.633319 4.44583 5.07915 0 10.5417 0C13.1925 0.000833333 15.6808 1.03333 17.5525 2.90667C19.4233 4.78 20.4533 7.27 20.4525 9.91833C20.45 15.3825 16.0042 19.8283 10.5417 19.8283C8.88332 19.8275 7.24915 19.4117 5.80165 18.6217L0.547485 20ZM6.04498 16.8275C7.44165 17.6567 8.77498 18.1533 10.5383 18.1542C15.0783 18.1542 18.7767 14.4592 18.7792 9.91667C18.7808 5.365 15.1 1.675 10.545 1.67333C6.00165 1.67333 2.30582 5.36833 2.30415 9.91C2.30332 11.7642 2.84665 13.1525 3.75915 14.605L2.92665 17.645L6.04498 16.8275ZM15.5342 12.2742C15.4725 12.1708 15.3075 12.1092 15.0592 11.985C14.8117 11.8608 13.5942 11.2617 13.3667 11.1792C13.14 11.0967 12.975 11.055 12.8092 11.3033C12.6442 11.5508 12.1692 12.1092 12.025 12.2742C11.8808 12.4392 11.7358 12.46 11.4883 12.3358C11.2408 12.2117 10.4425 11.9508 9.49665 11.1067C8.76082 10.45 8.26332 9.63917 8.11915 9.39083C7.97499 9.14333 8.10415 9.00917 8.22748 8.88583C8.33915 8.775 8.47499 8.59667 8.59915 8.45167C8.72499 8.30833 8.76582 8.205 8.84915 8.03917C8.93165 7.87417 8.89082 7.72917 8.82832 7.605C8.76582 7.48167 8.27082 6.2625 8.06499 5.76667C7.86332 5.28417 7.65915 5.34917 7.50749 5.34167L7.03249 5.33333C6.86749 5.33333 6.59915 5.395 6.37249 5.64333C6.14582 5.89167 5.50582 6.49 5.50582 7.70917C5.50582 8.92833 6.39332 10.1058 6.51665 10.2708C6.64082 10.4358 8.26249 12.9375 10.7467 14.01C11.3375 14.265 11.7992 14.4175 12.1583 14.5317C12.7517 14.72 13.2917 14.6933 13.7183 14.63C14.1942 14.5592 15.1833 14.0308 15.39 13.4525C15.5967 12.8733 15.5967 12.3775 15.5342 12.2742Z" fill="currentcolor" stroke="#fcfcfc"/>
  </g>
  <defs>
    <clipPath id="clip0_461_42949">
      <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
    </clipPath>
  </defs>
</svg>
                </a>
                
            </div>
            <ButtonSecondary direction={true} to="/plans" className="hidden"/>
            
            {/* Pass the closeMenu function as a prop */}
            {isMenuOpen && <NavOpen closeMenu={closeMenu} isMenuclosing={isMenuclosing} />}
        </div>
    );
};
export const NavOpen = ({ closeMenu,isMenuclosing }) => {


    const click=()=>
    {
      closeMenu();
    }


    return (
        
        <div className={`nav-open ${isMenuclosing ? 'fade-out':'' }`}>
{/*<i class="fa-solid fa-x close" ></i>*/}
    <div className="close">
            <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={closeMenu}>
<path d="M2.1999 22.5854L0.961426 21.367L10.7614 11.7251L0.961426 2.08324L2.1999 0.864746L11.9999 10.5066L21.7999 0.864746L23.0384 2.08324L13.2384 11.7251L23.0384 21.367L21.7999 22.5854L11.9999 12.9436L2.1999 22.5854Z" fill="#F7F5F2"/>
</svg>
</div>
            <div className="frame">
                <div className="div">
                    <div className="frame-2">
                        <NavLink exact to="/" className="text-wrapper" onClick={click} >HOME</NavLink>
                    </div>
                    <div className="frame-2">
                        <NavLink exact to="/login" className="text-wrapper" onClick={click} >LOGIN</NavLink>
                    </div>
                    <div className="frame-2">
                        <NavLink exact to="/plans" className="text-wrapper" onClick={click}>CORPORATE PLANS</NavLink>
                    </div>
                </div>
                <div  className="text-wrapper-2">imurs</div>
            </div>
        </div>
    );
};
