import { useState } from "react"
import "./FaQs.css"
import { NavLink } from "react-router-dom"

export const Note = ({logo,disc})=>{
    return(
        <NavLink to='tel:+919310473657' className="note">
            <div className="logo">{logo}</div>
            <div className="disc">{disc}</div>
        </NavLink>
    )
}

export const ExpandQuestion = ({question,answer}) =>{

    const [isExpand,setIsExpand] = useState(false);

    return(
        <div className={`set ${isExpand?'active2':''}`}>
            <div className="question-expand">
                <span className="question"  onClick={()=>{setIsExpand(!isExpand)}}>
                    {question}
                </span>
                <div className="expand" onClick={()=>{setIsExpand(!isExpand)}}>{isExpand?(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 3" fill="none">
  <path d="M0 1.5H18" stroke="#333332" stroke-width="1.5"/>
</svg>):(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
  <path d="M8.25 9.75H0V8.25H8.25V0H9.75V8.25H18V9.75H9.75V18H8.25V9.75Z" fill="#333332"/>
</svg>)}</div>
            </div>
            <div className={`answer ${isExpand?'active':''}`}>
                {answer}
            </div>
        </div>
    )
}

export const FaQ = ({hidden}) =>{
    return(
        <div className="body faq-body" style={{marginLeft:`${hidden?'100%':'0%'}`}}>
            <Note logo={'imurs'} disc={<>Looking for ideas or have any queries? Speak to your friend at imurs today.<br></br><a href="tel:+919310473657">Call for enquiry</a></>}/>
            <div className="heading">Explore more with FAQs</div>
            <div className="faq-cont">
            <ExpandQuestion
  question="How is an imurs magazine different from a regular photo album?"
  answer="Forget boring photo albums! imurs magazines are like storytellers crafted with your unique memories and narratives. Instead of just lining up photos, we dive into your stories, write them in an engaging way, and weave them into stunning layouts with chic design. Think of it as a timeless keepsake that breathes life into your special moments!"
/>

<ExpandQuestion
  question="How long does it usually take to get an imurs magazine?"
  answer="The wait won't be long! Once you share your cherished moments and photos, we'll create a digital draft for your approval (typically within 2-3 business days). Then, it takes just 5-7 business days for printing and delivery. Total turnaround is approximately 7-10 business days."
/>

<ExpandQuestion
  question="How many images do I need for one magazine?"
  answer="It depends on your chosen theme and edition, but we recommend 25-50 photos for perfect layout and design. Don't hesitate to share more though! Our design team will work their magic to curate the best selection and showcase your story flawlessly."
/>

<ExpandQuestion
  question="So, how much does this amazing magazine cost?"
  answer="Our pricing starts at ₹1449 for our basic plan and climbs with more pages and exciting features. We offer different packages to fit your budget and story size. Check www.iamyourstory.in/plans for detailed pricing."
/>

<ExpandQuestion
  question="How much customization is possible exactly?"
  answer="Plenty! Choose your theme, edition, cover images, fonts, and more. Personalize it further with handwritten notes, poems, quotes, or even interactive elements like quizzes and games."
/>

<ExpandQuestion
  question="I'm not sure what kind of memories to share. Can you help me with that?"
  answer="Don't worry about that! Each edition has pre-defined questions based on your chosen edition (like wedding), guiding you through key moments to share. Think of it as a conversation, where we ask and you just respond, but feel free to add any other special memories."
/>

<ExpandQuestion
  question="What is the process of getting one for myself?"
  answer="It's easy! Choose your plan and theme/edition and create your order. Fill out our short form to share your memories and upload photos. Sit back and relax as our talented design team creates a personalized draft for your approval. If you have any tweaks, let us know (we offer 2 free revisions)! Once you're happy, we'll print and deliver your edition right to your doorstep."
/>

<ExpandQuestion
  question="What happens if I need help filling out the form?"
  answer="Of course! If you need assistance with the form, our friendly customer support team is just a click or call away. You can even skip the form entirely and send your memories and photos directly over WhatsApp for a smooth and easy experience."
/>

<ExpandQuestion
  question="What happens if I'm not satisfied with the soft copy?"
  answer="We want you to love your edition! If you're not completely satisfied with the initial design, you can request revisions as long as they stay within the theme and edition you chose. We offer two free revisions, and additional revisions might incur a small fee."
/>

<ExpandQuestion
  question="How can I contact customer service if I have queries?"
  answer="We're happy to help! You can reach out to our friendly customer service team via email at support@iamyourstory.in, WhatsApp at +91 93104 73657, or even call us at the same during the business hours."
/>

<ExpandQuestion
  question="What is the payment process and refund policy?"
  answer="We accept secure payments via UPI, credit card, debit card, and net banking. You can choose to pay 50% or 100% upfront. Full payment comes with a 5% discount. We offer a full refund if you cancel your order before the design process begins. Once the design process has started, you can only request a refund up to 50% of the total price."
/>

<ExpandQuestion
  question="Do I need to write the content myself or do you write that?"
  answer="Don't worry about writing; simply share your precious moments with us through texts or voice notes or over a call itself. Our wordsmiths will whip up a fantastic narrative for your edition, turning your tales into pure magic."
/>

<ExpandQuestion
  question="What happens if my photos aren't high resolution?"
  answer="No need to stress about professional shots! We love the charm of phone photos – they capture real moments and add a personal touch."
/>

<ExpandQuestion
  question="How much does it cost to get more than one copy of my edition?"
  answer="When you order multiple copies, we simply charge the standardized printing cost only, usually between ₹200-400 depending on the chosen plan and number of pages. It's a great way to share your memories with loved ones without breaking the bank."
/>

<ExpandQuestion
  question="What if you don't have a theme I am looking for?"
  answer="If you can't find your desired theme, we're always open to ideas! Just drop us a message on +91 93104 73657, and we'll be thrilled to tailor a unique theme just for you, at no extra cost. We love collaborating to create special stories."
/>

<ExpandQuestion
  question="Can I split my payment into installments?"
  answer="We don't offer multiple installment plans, but we do have two convenient options: 50% upfront and 50% on delivery, or full upfront payment with a 5% discount. Choose the one that best suits your needs."
/>

            </div>
            
        </div>
    )
} 