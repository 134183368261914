import React, { useEffect, useState } from 'react'
import { InfiniteIconMarquee, InfiniteMarquee, RegistrationMarquee } from '../marquee/marquee'
import { PartnerInvite } from '../partnerInvite/partnerInvite'
import { ProductFlipBook } from './cards/productFlipbook/productFlipBook'
import { BasicHeading, HedingSubheding, MainHeading } from '../headings/heading'
import { Feedback, FeedbackDynamic } from '../feedback/courosal/feedback-cards/card'
import Gridproduct from './cards/gridproduct/gridproduct'
import { useLocation } from 'react-router-dom'
import { FlipbookContainer } from '../flipBookShow/flipBookShow'
import { FeedbackNew } from '../feedback/courosal/feedback1-cards/card1'
import "./product.css"
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination,Mousewheel } from 'swiper/modules';

export function Product(props) {

  
  const location = useLocation();
  const info = location.state?.info;
  
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // You can use 'auto' for instant scrolling or 'smooth' for smooth scrolling
  });
  
  // Outputs "1Jbrp-s-HvqhVgWQZeDx9_vbvRKZAnGWP"
  
  const url = new URL("https://drive.google.com/file/d/1Jbrp-s-HvqhVgWQZeDx9_vbvRKZAnGWP/view?usp=drive_link");
  const pathname = url.pathname;
  const fileId = pathname.split("/")[3];
  const images = info.images;
  const imageKeys = Object.keys(images);
  console.log(info)
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <>

<div className='body product-body' style={{ marginTop: "40px",display:'flex',flexDirection:'column',width:'100%'}}>
    
    {/* <Gridproduct info={info}/> */}

    <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 10,
            modifier: 1,
          }}
          mousewheel={{
            forceToAxis: true,
            releaseOnEdges: true,
            sensitivity: 1,
          }}
          initialSlide={1}
          pagination={true}
          modules={[EffectCoverflow, Pagination,Mousewheel ]}
          className="mySwiper"
        >
          {imageKeys.map((key, index) => (
            <SwiperSlide key={index}>
              <img
                onLoad={() => setImageLoaded(true)}
                src={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${images[key]}&cache-control=max-age=172800`}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
    </Swiper>
    
    <ProductFlipBook text={info.discription} thumb={info.thumbnail} pdf={info.pdf} name={info.title} category={info.category}  direction={info.direction}/>
    
    <div style={{display:'flex',flexDirection:'column',gap:'10px',width:'100%'}}>
    <InfiniteIconMarquee info={info.bullets}/>
    </div>
    <div className='product-feedback-cont'>
    <div style={{width:'fit-content',margin:'auto',marginBottom:'30px'}}>
      <BasicHeading text={info.feedBackTitle}/>
      </div>
       <FeedbackNew desk={true} name={info.custmerName} text={info.feedBack} img={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${info.customerImg}&cache-control=max-age=172800)`} city={info.custmerCity}  />
    </div>
</div>
    </>
  )
}

export function ProductB2B(props) {

  
  const location = useLocation();
  const info = location.state?.info;
  
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // You can use 'auto' for instant scrolling or 'smooth' for smooth scrolling
  });
  
  // Outputs "1Jbrp-s-HvqhVgWQZeDx9_vbvRKZAnGWP"
  
  const url = new URL("https://drive.google.com/file/d/1Jbrp-s-HvqhVgWQZeDx9_vbvRKZAnGWP/view?usp=drive_link");
  const pathname = url.pathname;
  const fileId = pathname.split("/")[3];
  const images = info.images;
  const imageKeys = Object.keys(images);
  const [imageLoaded, setImageLoaded] = useState(false);
  console.log(info)

  return (
    <div className='product-body' style={{width:'calc(100% + 40px)',marginLeft:'-20px',marginTop:'-40px',display:'inherit',flexDirection:'inherit',gap:'inherit',alignSelf:'self-start'}}>
    
    {/* <Gridproduct info={info}/> */}
    <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 5,
            stretch: 0,
            depth: 10,
            modifier: 1,
          }}
          initialSlide={1}
          pagination={true}
          mousewheel={{
            forceToAxis: true,
            releaseOnEdges: true,
            sensitivity: 1,
          }}
          modules={[EffectCoverflow, Pagination,Mousewheel ]}
          className="mySwiper"
        >
          {imageKeys.map((key, index) => (
            <SwiperSlide key={index}>
              <img
                onLoad={() => setImageLoaded(true)}
                src={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${images[key]}&cache-control=max-age=172800`}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
    <ProductFlipBook text={info.discription} thumb={info.thumbnail} pdf={info.pdf} name={info.title} isnew={props.isnew} category={info.category} direction={info.direction}/>
    <div style={{display:'flex',flexDirection:'column',gap:'10px',width:'100%'}}>
    <InfiniteIconMarquee info={info.bullets}/>

    </div>
    <div style={{width:'fit-content',margin:'auto'}}>
      <BasicHeading style = {{display:'flex'}} text={info.feedBackTitle}/>
      </div>
    <div className='product-feedback-cont'>
       <FeedbackNew desk={true} name={info.custmerName} text={info.feedBack} img={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${info.customerImg}&cache-control=max-age=172800)`} city={info.custmerCity}  />
    </div>
    </div>
  )
}