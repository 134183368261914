import { useState,useEffect } from "react"
import { BasicHeading, MainHeading } from "../headings/heading"
import { CategoryCard, PricingCardNewD2C, SubCategory, SuperCategory, customTitleCase } from "./card/pricingD2C"
import "./priced2c.css"
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import Success, { Loadin } from "../login/success"
import { ButtonPrimary } from "../button/button"
import Popup, { PopupBottom } from "../popup/popup"
import CustomDropdown from "../../dashboard/new-form/form-component/formcomponents"
import { INS } from "../../App"
import { Helmet } from 'react-helmet';

export const PriceD2C = () => {
  const navigate = useNavigate();


  
  // State to store the data from the API
  const [data, setData] = useState(null);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}api/d2cpricing`);
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        setData(data);
     
      } catch (error) {
       console.log(error)
      }
    };
  
    fetchData();
  }, []);


  const [content,setcontent] = useState(null);
  const handleCardClick = (planId,planname) => {
   

      
      setcontent(planId);
        


  };

const buttonclick=()=>
{
  if(content!==null)
 {
  localStorage.setItem('continueOrderLink',`/category?plan=${content}`)
navigate(`/category?plan=${content}`);
 }

}

  
 







  return (
    <div className="body">
      <Helmet>
            <title>Place an order</title>
      </Helmet>
      <MainHeading name="Select Your Plan" />
      {data!==null ? <div className="priceD2C-cont">
      
       <div   style={{background:data.pricingData[0]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[0]._id, data.pricingData[0].name)}>
          <PricingCardNewD2C info={data.pricingData[0]} flag={data.pricingData[0]._id===content?1:0} id={0}/>
        </div>
        <div style={{background:data.pricingData[1]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[1]._id, data.pricingData[1].name)}>
          <PricingCardNewD2C info={data.pricingData[1]} flag={data.pricingData[1]._id===content?1:0} id={1}/>
        </div>
        <div style={{background:data.pricingData[2]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[2]._id, data.pricingData[2].name)}>
          <PricingCardNewD2C info={data.pricingData[2]} flag={data.pricingData[2]._id===content?1:0} id={2}/>
        </div>
 
      </div>:<Loadin></Loadin>}
      <div onClick={buttonclick} style={{width:`${content!==null?'80%':'80%'}`,margin:'auto',position:`${content!==null?'sticky':'relative'}`,bottom:'0',backgroundColor:'var(--isabeline)',maxWidth:'425px',padding:'10px'}}>
        <ButtonPrimary text="Proceed"/>
      </div>  
    </div>
  );
};


export const PriceD2CCategory = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);



  const category = queryParams.get('category');
  const subcategoryname = queryParams.get('subcategoryname')
  const catName = queryParams.get('categoryname')


  
  // State to store the data from the API
  const [data, setData] = useState(null);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}api/d2cpricing`);
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        setData(data);
     
      } catch (error) {
       console.log(error)
      }
    };
  
    fetchData();
  }, []);


  const [content,setcontent] = useState(null);
  const handleCardClick = (planId,planname) => {
   
      setcontent(planId);
        
  };

const buttonclick=()=>
{
  if(content!==null)
 {
  localStorage.setItem('continueOrderLink',`/checkout?plan=${content}&category=${category}&categoryname=${catName}&subcategoryname=${subcategoryname.replaceAll(' ','%20').replaceAll('&','%26')}`)
navigate(`/checkout?plan=${content}&category=${category}&categoryname=${catName}&subcategoryname=${subcategoryname.replaceAll(' ','%20').replaceAll('&','%26')}`);
 }

}

  
 







  return (
    <div className="body">
      <Helmet>
            <title>Place an order</title>
      </Helmet>
      <MainHeading name="Select Your Plan" />
      {data!==null ? <div className="priceD2C-cont">
      
       <div   style={{background:data.pricingData[0]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[0]._id, data.pricingData[0].name)}>
          <PricingCardNewD2C info={data.pricingData[0]} flag={data.pricingData[0]._id===content?1:0} id={0}/>
        </div>
        <div style={{background:data.pricingData[1]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[1]._id, data.pricingData[1].name)}>
          <PricingCardNewD2C info={data.pricingData[1]} flag={data.pricingData[1]._id===content?1:0} id={1}/>
        </div>
        <div style={{background:data.pricingData[2]._id===content?'var( --newjet-black)':null,borderRadius:'var(--br-1)'}}  onClick={() => handleCardClick(data.pricingData[2]._id, data.pricingData[2].name)}>
          <PricingCardNewD2C info={data.pricingData[2]} flag={data.pricingData[2]._id===content?1:0} id={2}/>
        </div>
 
      </div>:<Loadin></Loadin>}
      <div onClick={buttonclick} style={{width:`${content!==null?'80%':'80%'}`,margin:'auto',position:`${content!==null?'sticky':'relative'}`,bottom:'0',backgroundColor:'var(--isabeline)',maxWidth:'425px',padding:'10px'}}>
        <ButtonPrimary text="Proceed"/>
      </div>  
    </div>
  );
};


export const CategoryWindow = () => {




  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);



  const plan = queryParams.get('plan');
  const planname = queryParams.get('planname')


 

 
  const categories = [ "Gifting", "Self", "Friendship", "Couple", "Travel", "Family"];
  const navigate = useNavigate();

  const handleCategoryClick = (categoryName) => {
    // Perform any additional logic if needed
    // For example, you can save categoryName to state or perform other actions

    // Navigate to the desired route
    navigate(`/sub_category?plan=${plan}&category=${encodeURIComponent(categoryName)}`);
  };

  return (
    <div className="body" style={{paddingBottom:'400px'}}>
      <MainHeading name="Select Your Theme"/>
      {/* Your other components */}
      <div className="category-cont">
        {categories.map((categoryName, index) => (
          <div
            key={index}
            style={{ width: 'fit-content' }}
            onClick={() => handleCategoryClick(categoryName)}
          >
            <CategoryCard categoryName={categoryName} />
          </div>
        ))}
      </div>
    </div>
  );
};



export const SubCategoryWindow = ()=>{
   



  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);



  const name = queryParams.get('category');

  const [data, setData] = useState(null);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}api/parent`);
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        for (let i = 0; i < data.pricingData.length; i++) {
          if (data.pricingData[i].name.toLowerCase() === name.toLowerCase()) {
            setData(data.pricingData[i].child);
          }
        }
        
     
      } catch (error) {
       console.log(error)
      }
    };
  
    fetchData();
  }, []);


console.log(data)




    return(
        <div className="body" style={{padding:'20px',paddingBottom:'280px'}}>
            
            {data!==null?
            <>
            <SuperCategory name={name}/>
            <div className="sub-category-cont">
            
            {data.map((subCategory, index) => (
    <SubCategory key={index} subCategory={subCategory} />
  ))}
            </div>
            
  </>:<Loadin></Loadin>
          }
        </div>
    )
}

const CustomRadio = ({ label, description, isSelected, onSelect,bubble }) => {
    return (
      <div className={`radio-checkout ${isSelected ? 'outline' : ''}`} onClick={onSelect}>
        <div className={`button ${isSelected ? 'checked' : ''}`}>
          {isSelected && <div className="fill"></div>}
        </div>
        <div className="text-wrap">
          <div className="heading">{label}</div>
          <p className="sub-heading">{description}</p>
          {bubble?<p style={{marginTop:'3px',padding:'6px 10px',backgroundColor:'rgba(51, 51, 50, 0.05)',width:'fit-content',borderRadius:'50px',border: '0.5px solid rgba(51, 51, 50, 0.08)'}} className="sub-heading">
             {bubble}
          </p>:null}
        </div>
      </div>
    );
  };

  export const D2COrdersummry = ({customer}) => {
    const [selectedOption, setSelectedOption] = useState('upfront');
    const navigate = useNavigate();
    const { search } = useLocation();
    localStorage.setItem('lastPayment',window.location.href)
    const queryParams = new URLSearchParams(search);
    const plan = queryParams.get("plan");
    const category = queryParams.get("category");
    const categoryplan = queryParams.get("categoryname");
    const subcategoryplan = queryParams.get("subcategoryname");
    const [data, setData] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [couponCode, setCouponCode] = useState('');
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [extracopies,setExtracopies] = useState(1)
    const [nameInput,setNameInput] = useState(false);


    if(!customer.phone){
      localStorage.setItem('continueOrderLink',window.location.href)
    window.location.href = `/login`;
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}api/d2cpricing`);
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          for (let i = 0; i < data.pricingData.length; i++) {
            if (data.pricingData[i]._id === plan) {
              setData(data.pricingData[i]);
              setDiscount(Math.ceil(data.pricingData[i].price*0.05))
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }, []);
    
    const pay = async () => {
      try {
        if(!customer.name || customer.name === ""){
          setNameInput(true)
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/paymentD2C`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            plan,
            category,
            selectedOption,
            couponCode,
            extracopies
          }),
          credentials: 'include',
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const responseData = await response.json();
        
        // Check if responseData has the expected structure before accessing its properties.
        if (responseData  && responseData.url) {
          window.location.href = responseData.url;
        } else {
          console.error('Unexpected response data:', responseData);
          // Handle unexpected response data as needed
        }
        setNameInput(false);

      } catch (error) {
        console.error('Error during fetch:', error);
        // Handle the error as needed
      }
    };
    
    const applyCoupon = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/applyCoupon`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            couponCode,
          }),
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
          setShowPopup(true)
        }
  
        const couponData = await response.json();
  
        // Check if couponData has the expected structure before accessing its properties.
        if (couponData && couponData.valid && couponData.discount) {
          document.querySelector('#apply').innerHTML = 'Applied';
          setAppliedCoupon(couponData);
          setShowPopup(true)
          set_popupheading('Yaay! Coupon Applied')
          set_popuptext(couponData.title)
          if (selectedOption === "upfront") {
            console.log('true')
            const magazinePrice = data.price;
            let total = magazinePrice;
            const maxDiscount = magazinePrice
            if (couponData && couponData.valid) {
              const discountedTotal = Math.ceil(Math.min(magazinePrice*(couponData.discount/100), maxDiscount));
              total -= discountedTotal
              console.log(total)
            }
            setDiscount(Math.ceil(total * 0.05));
          } else {
            setDiscount(0);
          }
          setTimeout(() => {
            setShowPopup(false)
              
          }, 2500);
        } else {
          console.error('Invalid coupon data:', couponData);
          setShowPopup(true)
          set_popupheading('Invalid coupon')
          set_popuptext('Oops! That coupon code seems to be invalid or expired. Double check it, please.')
          // Handle invalid coupon as needed
        }
      } catch (error) {
        console.error('Error during coupon fetch:', error);
        setShowPopup(true)
        
        // Handle the error as needed
      }
    };
  

    const calculateTotal = () => {
      const deliveryCharge = 70;
      const magazinePrice = data.price;
      let total = magazinePrice;
      const maxDiscount = magazinePrice
      if (appliedCoupon && appliedCoupon.valid) {
        const discountedTotal = Math.ceil(Math.min(magazinePrice*(appliedCoupon.discount/100), maxDiscount));
        total -= discountedTotal
      }
    
      if (selectedOption === "upfront") {
        // Apply 5% discount for upfront payment
        total -= discount;
      }

      total+=deliveryCharge
      total += (extracopies-1)*data.extraPrice;
    
      return total;
    };
    
    const calculatePayableAmount = () => {
      if (selectedOption === "downfront") {
        // Show payable amount as half of the total
        return Math.ceil(calculateTotal() / 2);
      } else {
        // For other options, payable amount is the same as the total
        return calculateTotal();
      }
    };
  
    const handleSelect = (option) => {
      setSelectedOption(option);
      if (option === "upfront") {
        const magazinePrice = data.price;
        let total = magazinePrice;
        const maxDiscount = magazinePrice
        if (appliedCoupon && appliedCoupon.valid) {
          const discountedTotal = Math.ceil(Math.min(magazinePrice*(appliedCoupon.discount/100), maxDiscount));
          total -= discountedTotal
        }
        setDiscount(Math.ceil(total * 0.05));
      } else {
        setDiscount(0);
      }
    };

    const [showPopup, setShowPopup] = useState(false);
    const [popuptext,set_popuptext] = useState('Something Went Wrong')
    const [popupheading,set_popupheading] = useState('Sorry')
    const [popUpsteps,setPopUpSteps] = useState(true)

    const FormGroup = (props) => (
      <div className="form-group">
        <div className="lable">
          {props.sequence !== "" ? <span className="sequence">{props.sequence}</span> : null}
          {props.label}
        </div>
        {props.inputType !== 'none' && (
          <input
            type={props.inputType}
            name={props.name}
            value={props.value}
            id={props.id}
            onChange={()=>{
              document.querySelector('#'+props.id).style.borderColor = '#333333';
            document.querySelector('#customer_name').style.borderBottom = '1px solid';

            }}
            disabled={props.disabled}
          />
        )}
      </div>
    );

    return data !== null ? (
      <>
      <div className="inform" style={{height:'0px',overflow:'hidden',padding:'0'}}></div> 

      <div className="body order-summry" >
        <MainHeading style={{width:'100%',margin:'0'}} name="Order Confirmation" />
        <div className="left" style={{maxWidth:'615px',width:'100%'}}>

        
        <Popup
        show={showPopup}
        heading={popupheading}
        message={popuptext}
        isNotification={true}
        onConfirmPopup={(result) => {
          setShowPopup(false);
        }}
      />

      <PopupBottom
       show={popUpsteps}
       heading={`How your order will be processed?`}
       message={popuptext}
       isNotification={true}
       onConfirmPopup={(result) => {
         setPopUpSteps(false);
       }}
      />
    
      <Popup
        show={nameInput}
        message={<div className="form" style={{minWidth:'230px'}}><FormGroup sequence="" label="What Should We Call You ?" type='text' name='customer_name' id='customer_name' /></div>
      }
        isNotification={true}
        onConfirmPopup={(result) => {
          if(document.querySelector('#customer_name').value === ''){
            document.querySelector('#customer_name').style.borderBottom = '3px solid var(--persian-red)';
            return
          }
          document.querySelector('.popup-cont').style.filter = "brightness(0.8)";
          document.querySelector('.popup-cont').style.pointerEvents = "none";
          fetch(process.env.REACT_APP_API_URL + 'api/updateD2CuserName', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({name:document.querySelector('#customer_name').value}),
          })
            .then((response) => {
              customer.name = document.querySelector('#customer_name').value;
              pay()
            })
        }}
      />
        <div className="cont">
          <img className="img" src="/physical_sample.jpg" alt="Magazine Cover" />
          <div className="text-wrap">
            <div className="heading">Imurs {data.name}</div>
            <div className="sub-heading">{data.description}</div>
            <div className="sub-heading"> {customTitleCase(subcategoryplan)} Edition </div>
          </div>
        </div>

        <CustomDropdown
        lable={'Number of copies'}
        options={[1,2,3,4,5]}
        style={{background:'rgba(51, 51, 50, 0.05)',border:'none',paddingLeft:'20px',paddingRight:'20px',padding:'15px',borderRadius:'var(--br-1)'}}
        selectedValue={extracopies}
        onSelect={(selectedValue)=>{setExtracopies(selectedValue)}}
        />
  
        <div className="bill">
          <div className="cont">
            <div className="svg-text">
              <div className="text">Imurs {data.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; x 1</div>
            </div>
            <div className="price">₹ {INS(data.price)}</div>
          </div>

          {appliedCoupon && appliedCoupon.valid && (
            <div className="cont">
            <div className="svg-text">
              <div className="text">Coupon Applied</div>
            </div>
            <div className="price">- ₹ {INS(Math.ceil(data.price*(appliedCoupon.discount/100)))}</div>
          </div>
          )}

          {discount ? (
          <div className="cont">
            <div className="svg-text">
              <div className="text">Upfront Saving</div>
            </div>
            <div className="price">- ₹ {INS(discount)}</div>
          </div>):null}

          {extracopies > 1 ? <div className="cont">
            <div className="svg-text">
              <div className="text">Extra copies &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; x {extracopies-1}</div>
            </div>
            <div className="price">₹ {INS(data.extraPrice * (extracopies-1))}</div>
          </div>:null
          }

  
          <div className="cont">
            <div className="svg-text">
              <div className="text">Shipping</div>
            </div>
            <div className="price">₹ 70</div>
          </div>
  
          <div className="total">
            <span>Total</span>
            <span>₹ {INS(calculateTotal())}</span>
          </div>
  
          {selectedOption === "downfront" && (
            <>
            <div className="total" style={{ color: 'var(--jet-black)',fontFamily:'HK Grotesk-Bold', border: 'none', padding: '0' }}>
              <span>Current Payable</span>
              <span>₹ {INS(calculatePayableAmount())}</span>
            </div>
            {/* <div className="total" style={{ color: 'var(--persian-red)', border: 'none', padding: '0' }}>
            <span>Due On Delivery</span>
            <span>₹ {INS(calculatePayableAmount())}</span>
          </div> */}
          </>
          )}
          {/* {appliedCoupon && appliedCoupon.discount + discount > data.price  && (
            <div className="total" style={{ color: 'var(--persian-red)', border: 'none', padding: '0' }}>
              <span>Max Discount</span>
              <span>₹ {data.price}</span>
            </div>
          )} */}
        </div>
        <div
        className="proceed-button"
        id = "proceed-button-d"
        onClick={() => {
          pay();
          // Disable pointer events for 5 seconds
          document.querySelector('#proceed-button-d').style.pointerEvents = 'none';
          document.querySelector('#proceed-button-d').style.backgroundColor = 'var(--jet-black-4)';
          setTimeout(() => {
            document.querySelector('#proceed-button-d').style.pointerEvents = 'auto';
            document.querySelector('#proceed-button-d').style.backgroundColor = 'var(--jet-black)';
          }, 7000);
        }}
      >
        Pay ₹ {INS(calculatePayableAmount())}
      </div>
        </div>

        <div className="right">
        <div className="coupan-cont">
        <div className="heading">Nostalgic Deals</div>
        <div className="input-cont">
          <input
            type="text"
            className="cupanCode"
            placeholder="APPLY COUPON"
            value={couponCode}
            onChange={(e) => {setCouponCode(e.target.value);document.querySelector('#apply').innerHTML = 'Apply'}}
          />
          <div className="but" id="apply" onClick={applyCoupon}>
            Apply
          </div>
        </div>
        {appliedCoupon && appliedCoupon.valid && (
          <div className="applied-coupon">
            Applied Coupon: - {INS(appliedCoupon.discount)} %
          </div>
        )}
      </div>
  
        <CustomRadio
          label="Pay 50%-50%"
          description="Start customization with 50% now, complete the payment on delivery"
          bubble = {selectedOption !== "upfront"?`Due on delivery only ₹ ${INS(Math.ceil(calculateTotal()/2))}`:false}

          isSelected={selectedOption === "downfront"}
          onSelect={() => {
            handleSelect("downfront");
          }}
        />
        <CustomRadio
          label="Pay Upfront"
          description="Save 5% upfront—a nod to nostalgia, wrapped in savings"
          bubble = {selectedOption !== "downfront"?`Savings ₹ ${INS(discount)}`:false}
          isSelected={selectedOption === "upfront"}
          onSelect={() => {
            handleSelect("upfront");
          }}
        />
        <div
        className="proceed-button"
        id = "proceed-button-d"
        onClick={() => {
          pay();
          // Disable pointer events for 5 seconds
          document.querySelector('#proceed-button-d').style.pointerEvents = 'none';
          document.querySelector('#proceed-button-d').style.backgroundColor = 'var(--jet-black-4)';
          setTimeout(() => {
            document.querySelector('#proceed-button-d').style.pointerEvents = 'auto';
            document.querySelector('#proceed-button-d').style.backgroundColor = 'var(--jet-black)';
          }, 7000);
        }}
      >
        Pay ₹ {INS(calculatePayableAmount())}
      </div>
      </div>
      </div>
      
     </>
    ) : (
      <Loadin />
    );
  };
  
  