import React from "react";
import "./heading.css";
import { ButtonPrimary } from "../button/button";

export const MainHeading = (props ) => {
    return (
        <div className="main-heading" style={props.style}>
            <div className="text-wrapper">{props.name}</div>
        </div>
    );
};

export const BasicHeading = ({text,style={}})=>{
    return (
        <div className="basic-heading hide-desktop" style={style}>
            <div className="text-wrapper">{text}</div>
        </div>
    );
}

export const HedingSubheding = (props) => {
    return (
        <div className={"heding-subheding "+props.className} style={props.style}>
            <div className="headding">{props.heading}</div>
            <div className="sub-heading" style={props.subStyle}>{props.sub_heading}</div>
        </div>
    );
};

export const HedingSubhedingSimple = (props) => {
    return (
        <div className="heding-subheding-simple">
            <div className="headding">{props.heading}</div>
            <div className="sub-heading">{props.sub_heading}</div>
        </div>
    );
};

export const HedingSubhedingButton = (props) => {
    console.log(props)
    return (
        <div className="heding-subheding-button" style={props.customStyle}>
            <div className="headding">{props.heading}</div>
            <div className="sub-heading_2">{props.sub_heading_2}</div>
            <div className="sub-heading">{props.sub_heading}</div>
            <ButtonPrimary direction={true} to={props.direction?props.direction:'/createOrder'} customStyle={{maxWidth:'fit-content',marginLeft:'0',marginTop:'10px'}} text={ props.button || 'Create Order'} />
        </div>
    );
};

export const SimpleHeading = ({text,customStyle}) =>{
    return (
        <div className="simple-heading">
            <div className="text-wrapper" style={customStyle}>{text}</div>
        </div>
    );
}

export const IconDiscriptin = (props) =>{
    return(
        <div className="IconDiscriptin">
            <SimpleHeading customStyle = {{marginBottom:'10px',fontFamily:'HK Grotesk-Medium',fontWeight:'500'}} text={props.heading}/>
            <div className="cont">
            {props.words.map((word, index) => (
                <div className="text-wraper" key={index}>
                    <img className="icon" src={word.icon}alt='icon' />
                    <div className="text">{word.text}</div>
                </div>
            ))
            }
                

            </div>
        </div>
    )
}