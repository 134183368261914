import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './courosal.css';

// import required modules
import { EffectCoverflow, Pagination,Mousewheel } from 'swiper/modules';
import { FeedbackNew } from '../feedback1-cards/card1';
import { BasicHeading, MainHeading } from '../../../headings/heading';

export default function Courosal() {
  return (
    <div style={{marginTop:'0px'}}>
      <div style={{width:'fit-content',margin:'auto'}}>
      <BasicHeading text="Words from Our World"/>

      </div>

      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        initialSlide={1}
        loop={true}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 10,
          modifier: 1,
        }}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
          sensitivity: 1,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination,Mousewheel]}
        className="mySwiper"
      >
        <SwiperSlide>
          <FeedbackNew name="Nitya and Shivam" img="feedbackimages/Nitya_&_Shivam.jpeg"  text="Our memories turned into a beautiful tangible keepsake. Loved this concept" city="Get Souvenir"/>
        </SwiperSlide>
        <SwiperSlide>
          <FeedbackNew name="Mahi and Siddharth" img="feedbackimages/mahi_&_Siddharth.jpg" text="Reliving our special day through its pages brought tears and joy. Grateful!" city="Get Souvenir"/>
        </SwiperSlide>
        <SwiperSlide>
          <FeedbackNew name="Harshita and Akhil" img="feedbackimages/Harshita_&_Akhil.jpg" text="Each page captured our love story perfectly. Cherished memories beautifully preserved!" city="Get Souvenir"/>
        </SwiperSlide>
        <SwiperSlide>
          <FeedbackNew name="Namita and Akshay" img="feedbackimages/Namita_&_Akshay.jpg"  text="Imurs exceeded expectations, beautifully curated. It's our love story in print!" city="Get Souvenir"/>
        </SwiperSlide>
        <SwiperSlide>
          <FeedbackNew name="Jasmine and Siddharth" img="feedbackimages/Jasmine_&_Siddharth.jpg"  text="A heartfelt journey through memories on our coffee table. Thankful to Imurs for this." city="Get Souvenir"/>
        </SwiperSlide>
        <SwiperSlide>
          <FeedbackNew name="Sahiba and Mayank" img="feedbackimages/Mayank_&_Sahiba.jpg"  text="Every page radiated our love story—it's more than a keepsake; it's priceless joy!" city="Get Souvenir"/>
        </SwiperSlide>
      </Swiper>
       
    </div>
  );
}
