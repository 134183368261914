import React, { useState, useEffect } from "react";
import "./corporate.css";
import { BasicHeading, MainHeading } from "../../headings/heading";

export const Corporate = (props) => {
    const [bundlesData, setBundlesData] = useState([]);
    const [selectedMagazine, setSelectedMagazine] = useState(
        "Select the number of"
    );

    useEffect(() => {
        // Set props.bundles in the bundlesData state
        setBundlesData(props.bundles);
        props.onMagazineSelect(10);
        setSelectedMagazine(10);
    }, [props.bundles]);

    // Function to handle selection and send selected magazine back to the parent component
    const handleSelect = (magazine) => {
        setSelectedMagazine(magazine);
        // Send the selected magazine back to the parent component
        props.onMagazineSelect(magazine);
    };

    return (
        <div className="corporate">
            {/* <div className="corporate-wrapper">Corporate Plans</div> */}
            {!props.heading && <BasicHeading text="Corporate Plans" />}
            <div className="dropdown">
                <button
                    className="btn dropdown-toggle corporate-div"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <div className="corporate-wrapper-2">
                        {selectedMagazine < 10 ? "0" : ""}
                        {selectedMagazine}
                        {selectedMagazine < 2 ? " edition" : " editions"}
                    </div>
                </button>
                <ul className="dropdown-menu" style={{ width: "100%" }}>
                    {bundlesData.map((perMagazine, index) => (
                        <li key={index}>
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleSelect(perMagazine)}>
                                {perMagazine < 10 ? "0" : ""}
                                {perMagazine}
                                {perMagazine < 2 ? " edition" : " editions"}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            {!props.heading && (
                <p
                    className="bundle"
                    style={{
                        display:
                            selectedMagazine === "Select the number of"
                                ? "block"
                                : "none",
                    }}>
                    ( Select a Bundle to view pricing )
                </p>
            )}
        </div>
    );
};
