import './contact.css'

export const ContactPhone = ({style={}}) =>{
    return(
        <div className='contact' style={style}>
            <img src='/logos/call.svg' alt='phone'/>
            <div>
            Need Help? <a href="tel:+919310473657"> Call Us</a>
            </div>
        </div>
    )
}
export const ContactWhatsapp = () =>{
    return(
         <div className='contact' style={{margin:'0'}}>
<svg xmlns="http://www.w3.org/2000/svg" className="svg-img" viewBox="0 0 20 20" height='100%' width="auto" fill="currentcolor"><path d="M0.753464 19.2866C1.06049 18.1725 1.3394 17.121 1.64331 16.0765C1.6892 15.9377 1.70201 15.7901 1.68071 15.6455C1.65941 15.5009 1.6046 15.3633 1.52065 15.2436C-2.0145 9.51708 0.890183 2.14676 7.38003 0.352234C13.005 -1.20323 18.8464 2.56083 19.7699 8.33739C20.6464 13.8194 16.9527 18.9624 11.5042 19.7999C9.1355 20.1639 6.90268 19.7218 4.82221 18.51C4.62509 18.4071 4.39818 18.3765 4.18081 18.4233C3.13315 18.6725 2.09409 18.9585 1.0519 19.2304C0.979245 19.2491 0.904245 19.2585 0.753464 19.2866ZM13.0785 15.6819C13.2345 15.6945 13.3912 15.6945 13.5472 15.6819C14.8902 15.4624 16.0582 14.6663 16.0082 13.1132C16.0035 12.9569 15.8785 12.7225 15.7441 12.6554C14.9722 12.2561 14.1816 11.8944 13.3949 11.521C13.0777 11.3702 12.8402 11.4327 12.6285 11.7366C12.3769 12.0983 12.0738 12.4241 11.7972 12.7686C11.6285 12.9788 11.4332 13.0358 11.1777 12.9343C9.39565 12.2272 8.03315 11.035 7.05503 9.3913C6.92456 9.17255 6.93784 8.98661 7.10034 8.78661C7.35631 8.49243 7.58929 8.179 7.79721 7.84911C7.89487 7.68192 7.94956 7.41317 7.8855 7.2413C7.59331 6.44989 7.2605 5.67333 6.91987 4.90223C6.84721 4.73817 6.6894 4.52802 6.5394 4.49833C5.92925 4.37723 5.31753 4.37177 4.84878 4.88895C3.7355 6.11864 3.57846 7.51083 4.27612 8.93739C5.48159 11.4061 7.28393 13.3624 9.72534 14.6616C10.7636 15.2132 11.8636 15.6663 13.0785 15.6819Z"></path></svg>
         <span>
         <a href="https://wa.me/+919310473657">Need Help? </a>
         </span>
     </div>
    )
}
//9310473657