import { NavLink } from "react-router-dom";
import "./topmarquee.css"

export const EditionsMarque = (props) => {
  return (
    <NavLink to={props.link} className="editions-marque">
      <div className="group">
        <img src={props.img} alt="img"/>
      </div>
      <div className="frame">
        <p className="birthday-edition">
          <span className="text-wrapper">{props.cat} </span>
          {/* <span className="span">Edition</span> */}
        </p>
         <p className="div">
          {props.sub || `A toast to the next adventure & whispering a warm "thank you".`}
        </p> 
      </div>
    </NavLink>
  );
};