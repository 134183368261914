import { NavLink } from "react-router-dom"
import { ButtonBlack } from "../../component/button/button"
import { BasicHeading, MainHeading, SimpleHeading } from "../../component/headings/heading"
import { CreateOrder, Reqsample } from "../Reqsamplenav/reqsample"
import { OrderCard } from "../cards/orderCard"
import { ButtonPrimary, ButtonSecondary } from "../dash_buttons/buttons"
import { TagCredit, Tag_1 } from "../headings/headings"
import Profile_Settings from "../profileSetting/form"
import { Dashboard } from "../structure/structure"

import React, { useState, useEffect } from 'react';
import { OrderCardV2 } from "../cards/ordercardv2"
import Ordercheck from "../../component/test/test"
import { Loadin } from "../../component/login/success"

function mapStatusToString(status) {
  switch (status) {
      case 0:
      case 1:
        return "Active";
      case 2:
      case 3:
        return "In Progress";
      case 4:
      case 5:
        return "Printing";
      case 6:
          return "Shipped";
      default:
          return "Unknown Status";
  }
}

function mapStatusimgToString(status) {
switch (status) {
    case 0:
    case 1:
      return "active";
    case 2:
    case 3:
      return "inprocess";
    case 4:
    case 5:
      return "print"
    case 6:
        return "shipped";
    default:
        return "Unknown Status";
}
}

function mapStatusbuttonToString(status) {
switch (status) {
    case 0:
        return "Abstract Form";
    case 1:
    case 2:
      return "Track Order"
    case 3:
      return "Preview"
    case 4:
    case 5:
        return "Track Order";
    case 6:
        return "Track Order";
    default:
        return "Unknown Status";
}
}

function mapStatusbuttonToLink(status) {
switch (status) {
    case 0:
        return "/abstract/";
    case 1:
    case 2:
      return "/tracking/"
    case 3:
      return "/edition/"
    case 4:
    case 5:
        return "/tracking/";
    case 6:
        return "/tracking/";
    default:
        return "Unknown Status";
}
}


export const OrdersB2B = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    // Data to send to the backend
    const requestData = {
        _id:props.data._id
    };
  
    fetch(process.env.REACT_APP_API_URL + 'api/checkSample', {
      method: 'POST', // Use the appropriate HTTP method (e.g., POST)
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.shouldShowAlert) {
          setShowAlert(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // Empty dependency array indicates that this effect should run once when the component mounts
  

  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const partnerId = props.data._id; // Replace with the actual partner ID

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/show-order/${partnerId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data);
        setError(null);
        setLoading(false);
        console.log(data)
      })
      .catch((error) => {
        setOrders([]);
        setError('Failed to fetch orders');
        console.error('Error fetching orders:', error);
      });
  }, [partnerId]);



return (
  <Dashboard data={props.data}>
    {<NavLink to="/createOrder"><CreateOrder data={props.data} /></NavLink>}
    {showAlert && <div className="alert">Your Sample Will be Delivered Shortly</div>}
    {loading ? (
      // Show loading indicator while fetching
      <Loadin/>
    ) : (
      <>
      {orders.length === 0 ? (
          <Ordercheck heading="You have not created any orders yet" button = "Create Order" direct="/createOrder"/>
        ) : (
<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: "20px",marginBottom:'100px' }}>
      {orders
        .slice() // Create a shallow copy of the array to avoid mutating the original array
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort in descending order based on the date
        .map((order) => (
          <OrderCardV2 btb={true} ShareLinkB2B={true} link={mapStatusbuttonToLink(order.orderStatus) + order._id} name={order.plan?order.plan.name.split(' ')[1]:'idyllic'} key={order._id} order={order} status={mapStatusToString(order.orderStatus)} img={mapStatusimgToString(order.orderStatus)} thumbnail={order.thumbnail} buttonstatus={mapStatusbuttonToString(order.orderStatus)} />
        ))}
    </div>)}
      </>)}
    
  </Dashboard>
);

};

export const OrdersD2C = () => {

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/show-order-D2C/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data);
        setLoading(false); // Set loading to false once data is fetched
        setError(null);
        console.log(data);
      })
      .catch((error) => {
        setOrders([]);
        setLoading(false); // Set loading to false in case of an error
        setError('Failed to fetch orders');
        console.error('Error fetching orders:', error);
      });
  }, []);

//   function mapStatusToString(status) {
//     switch (status) {
//         case 0:
//             return "Active";
//         case 1:
//         case 2:
//           return "In Process"
//         case 3:
//         case 4:
//         case 5:
//         case 6:
//             return "Shipped";
//         default:
//             return "Unknown Status";
//     }
// }

// function mapStatusimgToString(status) {
//   switch (status) {
//       case 0:
//           return "active";
//       case 1:
//       case 2:
//         return "inprocess";
//       case 3:
//       case 4:
//       case 5:
//       case 6:
//       return "shipped";

//       default:
//           return "Unknown Status";
//   }
// }

// function mapStatusbuttonToString(status) {
//   switch (status) {
//       case 0:
//           return "Fill Form";
//       case 1:
//           return "Track Order"
//       case 2:
//           return "Review"
//       case 3:
//       case 4:
//       case 5:
//           return "Track Order";
//       case 6:
//           return "Shipped";
//       default:
//           return "Unknown Status";
//   }
// }

// function mapStatusbuttonToLink(status) {
//   switch (status) {
//       case 0:
//           return "/abstract/";
//       case 1:
//           return "/tracking/"
//       case 2:
//           return "/edition/"
//       case 3:
//       case 4:
//       case 5:
//           return "/tracking/";
//       case 6:
//           return "Shipped";
//       default:
//           return "Unknown Status";
//   }
// }

return (
  <>
    {loading ? (
      // Show loading indicator while fetching
      <Loadin/>
    ) : (
      <>
        {orders.length === 0 ? (
          <Ordercheck />
        ) : (
          <div className="body" style={{minHeight:'100%'}}>
            <div style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}>
            <BasicHeading text='Your Orders'/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: "20px" }}>
      {orders
        .slice() // Create a shallow copy of the array to avoid mutating the original array
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort in descending order based on the date
        .map((order) => (
          <OrderCardV2 btb={false} ShareLinkB2B={false} _id = {order._id} link={mapStatusbuttonToLink(order.orderStatus) + order._id} key={order._id} name={order.plan?order.plan.name:'idyllic'} order={order} status={mapStatusToString(order.orderStatus)} img={mapStatusimgToString(order.orderStatus)} thumbnail={order.thumbnail} buttonstatus={mapStatusbuttonToString(order.orderStatus)} />
        ))}
    </div>
    <NavLink to='/plans' className="create-order-fix">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M10.125 11.875H0.5V10.125H10.125V0.5H11.875V10.125H21.5V11.875H11.875V21.5H10.125V11.875Z" fill="#fff"/>
</svg>
    </NavLink>
          </div>
        )}
      </>
    )}
  </>
);
};
