import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HedingSubheding, HedingSubhedingSimple } from '../headings/heading';
import FlipbookPreview from '../dearFlip/dearFlip2';
import { Loadin } from '../login/success';
import { ButtonPrimary, ButtonSecondary } from '../button/button';
import Popup from '../popup/popup';
import "./preview.css"
import { customTitleCase } from '../pricingD2c/card/pricingD2C';
export const PreviewWindow = () => {
  const { edition } = useParams();
  const [editionInfo, setEditionInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEditionInfo = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + 'api/editions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ edition }),
        });

        const data = await response.json();
        setEditionInfo(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching edition information:', error);
        setLoading(false);
      }
    };

    fetchEditionInfo();
  }, [edition]);

  const [popupText,setPopUpText]= useState("");
  const [popUpButtonText,setPopUpButtonText]= useState("");
  const [popRedirectLink,setPopRedirectLink]= useState("");

  const [popup,setPopup]= useState(false);

  const handleRequestRevision = async () => {
    try {
      

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/request-revision`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          edition:editionInfo._id

        }),
      });

      const data = await response.json();
      editionInfo.live = 2
      setPopUpText("Sure, let’s revise !")
      setPopUpButtonText("Share Revisions")
      setPopRedirectLink(`https://api.whatsapp.com/send?phone=${editionInfo.editor.phone.countryCode+editionInfo.editor.phone.number}&text=Hi%2C%20I%20would%20like%20to%20share%20the%20following%20revisions%20regarding%20my%20${editionInfo.category.name}%20edition%20Order%20No.%20${editionInfo._id}`      )
      setPopup(true)
      // Handle the response as needed
      console.log('Request Revision Response:', data);
      // You can also trigger additional actions based on the response
      // For example, show a success message or update the UI
    } catch (error) {
      console.error('Error requesting revision:', error);
  
      // Handle errors, show an error message, etc.
    } 
  };
  
  const handleApprove = async () => {
    
    try {
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          edition:editionInfo._id
        }),
      });
   
      const data = await response.json();
      editionInfo.live = 3
      // Handle the response as needed
      console.log('Approve Response:', data);
      setPopUpText("Edition Approved")
      if(editionInfo.payment.total<editionInfo.payment.paid){
        setPopUpButtonText("next")
      }else{
        setPopUpButtonText("Track Order")
      }
      setPopRedirectLink('/tracking/'+editionInfo._id)
      setPopup(true)
      // You can also trigger additional actions based on the response
      // For example, show a success message or update the UI
    } catch (error) {
      console.error('Error approving:', error);
  
      // Handle errors, show an error message, etc.
    }
  };
  
  return (
    <>
      <Popup
      isNotification={true}
      show={popup}
      heading={popupText}
      DEF_OK={popUpButtonText}Share Revisions
      onConfirmPopup={()=>{setPopup(false);window.location.href=popRedirectLink}}
      ></Popup>
      {loading ? (
        <Loadin />
      ) : (
        <>
          <HedingSubheding heading={`${customTitleCase(editionInfo.customerName)}'s ${customTitleCase(editionInfo.category.name)} Edition`} sub_heading={editionInfo.live < 3 ? (editionInfo.live === 1 ? "Soft Copy Preview" : "Under Revision") : ("Soft Copy")} />
          <div id='prod-skele' className='' style={{marginTop:'-40px'}}>
          <FlipbookPreview source={editionInfo.pdf[0]} />
          </div>
          {(editionInfo.pdf.length && editionInfo.live < 2) ? (
            <div className='bottom-buttons' style={{ width: '100%', display: 'flex', gap: '20px', maxWidth: '600px' }}>
              <ButtonSecondary customStyle={{ minWidth: '55%' }} text="Ask for Revision" onClick={handleRequestRevision} />
              <ButtonPrimary customStyle={{ minWidth: '0px',border:'5px solid var(--jet-black)' }} text="Approve" onClick={handleApprove} />
            </div>
          ) : null}
        </>
      )}
    </>
  );
  

};

export default PreviewWindow;
