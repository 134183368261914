import React from "react";
import './cancillation.css'

export const AboutUs = ()=>{
  return(
    <div className="body">
 <div className="legal-cont">
      <h1>Introducing Imurs</h1>
      <p>
         At Imurs, we believe in the power of memories, and hence our mission is to offer you a unique way to preserve and celebrate them. As that old school saying is life is all about creating memories, we are here just to take this saying one step ahead into ‘life is all about creating and preserving memories’. A shelf either in your living room or your bed room, full of different editions of personalized magazines,depicting different phases of your family or you life. Think about it.
      </p>

      <h1>Our Vision</h1>
      <p>
      Imurs goes beyond traditional gifting, transcending materialistic presents to offer an unparalleled experience. Whether it's a birthday, anniversary, farewell, or a special travel adventure, we create personalized magazines that encapsulate your stories, making them the perfect gift for yourself or your loved ones.
      </p>

      <h1>Personalized/Immaterialistic Gifting for Every Occasion</h1>
      <p>Discover Imurs, your destination for unique and personalized gifts transforming cherished moments into timeless memories. Explore our range of categories, each crafted to capture the essence of special occasions.</p>
      
      <h3>Birthday Edition: A Journey Through Your Years</h3>
      <p>
      Create a customized birthday magazine, reliving your fondest memories. Perfect for milestone birthdays, this personalized keepsake encapsulates the joy, laughter, and growth of each passing year.
      </p>

      <h3>Anniversary Edition: Celebrate Your Love Story</h3>
      <p>
      Our personalized anniversary magazines beautifully narrate the chapters of your love story. From the magical moments of your proposal to the joyous wedding day, this keepsake immortalizes your journey together.
      </p>

      <h3>Retirement Edition: Commemorate a Fulfilling Career</h3>
      <p>
      Craft a personalized magazine to honor a remarkable career. Share anecdotes, achievements, and the wisdom gained over the years, creating a unique gift for someone stepping into retirement.
      </p>

      <h3>Proposal Edition: Capturing the Magical Moment</h3>
      <p>
      Relive the magic of your proposal with a personalized magazine. Document the heartfelt journey leading to the pivotal moment, creating a unique keepsake filled with love and nostalgia.
      </p>

      <h3>Wedding Edition: A Journey Down the Aisle</h3>
      <p>
      Preserve the joyous moments of your special day with a customized wedding magazine. From the pre-wedding excitement to the ceremony's magical vows, every detail becomes a cherished memory.
      </p>

      <h3>Couple Edition: Your Unique Story, Your Unique Theme</h3>
      <p>
      Craft a personalized magazine around your unique theme or story. Perfect for couples commemorating anniversaries, first dates, or any special moments that define their relationship.
      </p>

      <h3>Family Edition: Treasured Memories for Generations</h3>
      <p>
      Create a shared treasure with a personalized family magazine. Ideal for any family member, this keepsake captures the essence of family bonds and milestones, preserving them for generations to come.
      </p>

      <h3>Friendship Edition: Celebrate Bonds That Last a Lifetime</h3>
      <p>
      Give the gift of memories with a personalized friendship magazine. Ideal for Friendship Day or special occasions, this keepsake celebrates the enduring bonds of friendship.
      </p>

      <h3>Siblings Edition: Express Love in a Unique Way</h3>
      <p>
      Siblings Edition: Express Love in a Unique Way
Create a personalized magazine to express your love for your siblings. Capture shared memories, inside jokes, and the unique bond that makes your sibling relationship special.

      </p>
      
      <h3>Graduation Edition: Shaping Your Academic Journey</h3>
      <p>
      Preserve the memories that shaped your academic journey with a personalized college days magazine. Ideal for graduates, this keepsake captures the experiences, friendships, and growth during college
      </p>

      <h1>Get Started</h1>
      <p>
        Imurs is not just a brand; it's a celebration of moments. Join us on a journey where every page tells a unique tale, and every memory becomes a work of art. Explore the joy of personalized gifting with Imurs.
      </p>
    </div>
    </div>
   
  )
}

export const Cancillation = ()=>{
    return(
        <div className="body">
            <div className="legal-cont">
                <h1>Cancellation and Refund Policy</h1>
                <p>
                    As our product is 100% customized to your preferences, we would like to
                    outline our cancellation and refund policy below:{" "}
                </p>
                <h3>Cancellation:</h3>
                <p>
                    Once your magazine goes into the production process, we are unable to offer
                    any refunds or cancellations. This is because your magazine is uniquely
                    created based on your specifications and cannot be resold to other
                    customers.
                </p>
                <h3>Changes to Magazine:</h3>
                <p>
                    After the final draft of your magazine is shared with you, you are allowed
                    to request changes only once. This includes modifications to content,
                    layout, or any other design elements. We will make every effort to
                    accommodate your changes within the scope of the original order, but please
                    note that major changes may result in additional charges and may impact the
                    delivery timeline.
                </p>
                <h3>Refund:</h3>
                <p>
                    Due to the personalized nature of our product and the costs involved in
                    production, we do not offer refunds once the magazine goes into the
                    production process. We are committed to ensuring that you are satisfied with
                    the final product and will work with you to address any concerns or issues
                    that may arise during the process.
                </p>
                <p>
                    Please review your order carefully before finalizing it to avoid any
                    discrepancies or changes after the production process has begun. If you have
                    any questions or need further clarification, please feel free to contact our
                    customer support team at support@iamyourstory.in
                </p>
            </div>
        </div>
    )
}
export const Privacy = ()=>{
    return(
        <div className="body">
            <div className="legal-cont">
            <h1>Privacy Policy for ImUrs</h1>
  <p>
    At ImUrs, we take your privacy seriously. This privacy policy outlines how
    we collect, use, and protect your personal information when you use our
    landing page. By using our landing page, you agree to the terms outlined in
    this privacy policy.
  </p>
  <h3>Information Collection:</h3>
  <p>
    When you fill out the forms on our landing page, we may collect the
    following personal information
  </p>
  <ul>
    <li>Name</li>
    <li>Email addresss</li>
    <li>Phone number</li>
    <li>Any other information you provide in the form</li>
  </ul>
  <h3>Use of Information:</h3>
  <p>
    We collect this information solely to provide you with the information you
    have requested from us. We may also use your information to:
  </p>
  <ul>
    <li>Respond to your inquiries and requests</li>
    <li>
      Send you marketing and promotional materials (if you have opted-in to
      receive them)
    </li>
    <li>Improve our website and marketing efforts</li>
  </ul>
  <h3>Sharing of Information:</h3>
  <p>
    We do not sell, trade, or otherwise transfer your personal information to
    third parties. We may share your personal information if required by law or
    to protect our rights and property.
  </p>
  <h3>Data Security:</h3>
  <p>
    We take data security seriously and implement industry-standard measures to
    protect your personal information from unauthorized access, use, or
    disclosure. We use SSL encryption technology to protect the data you submit
    through our forms.
  </p>
  <h3>Your Rights:</h3>
  <p>You have the right to:</p>
  <ul>
    <li>Access the personal information we have collected about you</li>
    <li>Correct any errors in your personal information</li>
    <li>Request that we delete your personal information</li>
    <li>Opt-out of receiving promotional emails</li>
  </ul>
  <p>
    To exercise these rights, please contact us using the contact information
    provided below.
  </p>
  <h3>Changes to Privacy Policy:</h3>
  <p>
    We reserve the right to modify this privacy policy at any time. Any changes
    made will be reflected on this page. We encourage you to review this privacy
    policy regularly to stay informed about how we are protecting your personal
    information.
  </p>
  <h3>Contact Us:</h3>
  <p>
    If you have any questions or concerns about this privacy policy or our data
    collection practices, please contact us at support@iamyourstory.in.
  </p>
            </div>
        </div>

    )
}
export const TandC = ()=>{
    return(
        <div className="body">
            <div className="legal-cont">
  <h1>Terms and Conditions for ImUrs</h1>
  <ol>
    <h3>
      <li> Acceptance of Terms</li>
    </h3>
    <p>
      By accessing and using iamyourstory.in, you agree to be bound by the terms
      and conditions set forth in this agreement.
    </p>
    <h3>
      <li>Description of Services</li>
    </h3>
    <p>
      ImUrs is a product based company that creates personalized and 100%
      customized magazines for customers based on their stories. We provide an
      abstract form for filling up and uploading stories and snapshots out of
      which an ‘I Am Your Story’ Magazine is drafted.
    </p>
    <h3>
      <li>Customization and Changes</li>
    </h3>
    <p>
      Customers are allowed to request changes only once to their magazine after
      the final draft is shared with them for approval. Additional changes may
      be subject to additional fees.
    </p>
    <h3>
      <li>Copyright and Ownership</li>
    </h3>
    <p>
      Customers retain ownership and copyright of the photos and story they
      provide for their magazine. By submitting content to the website,
      customers grant ImUrs a license to use, reproduce, and display the content
      solely for the purpose of creating and delivering the magazine.
    </p>
    <h3>
      <li>Payment and Refunds</li>
    </h3>
    <p>
      Customers will be charged for their magazine before ImUrs Team starts
      working on it. Once the magazine comes in process, no refund can be
      requested as this is a customized product.
    </p>
    <h3>
      <li>Delivery and Shipping</li>
    </h3>
    <p>
      Magazines are conceptualized, designed, printed and shipped just within a
      72 hours timeframe after the customer has completed their abstract form
      and made payment. Shipping times and fees may vary depending on the
      customer's location and shipping preferences.
    </p>
    <h3>
      <li>Limitation of Liability</li>
    </h3>
    <p>
      ImUrs reserves the right to modify these terms and conditions at any time
      without prior notice. Continued use of the website after any modifications
      constitutes acceptance of the new terms and conditions.
    </p>
    <h3>
      <li>Changes to Terms and Conditions</li>
    </h3>
    <p>
      ImUrs reserves the right to modify these terms and conditions at any time
      without prior notice. Continued use of the website after any modifications
      constitutes acceptance of the new terms and conditions.
    </p>
    <h3>
      <li>Governing Law</li>
    </h3>
    <p>
      Any claim related to ImUrs website shall be governed by the laws of India
      without regards to its conflict of law provisions.
    </p>
    <h3>
      <li>A Product Of Satya Agency</li>
    </h3>
    <p>
    Imurs is a product of Satya Agency therefore Satya Agency will be responsible for any dispute/complaint against Imurs.
    </p>
    <p>
      By using Iamyourstory.in, you agree to abide by these terms and
      conditions. If you do not agree to these terms and conditions, please do
      not use the website. If you have any questions or concerns, please contact
      us at support@iamyourstory.in.
    </p>
  </ol>
</div>
        </div>
    )
}
export const ContactUS = () =>{
    return(
        <div className="body">
            <div className="legal-cont">
                <h1>Contact Us</h1>
                <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                    <a href="tel:+916392534772" style={{color:'var(--Jet-Black)'}}>
                    +91 9310473657
                    </a>
                </div>
                <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>
                    <a>397/13-B Meerapur, Prayagraj - 211003</a>
                </div>
                <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
</svg>
                    <a>info@iamyourstory.in</a>
                </div>
                </div>

        </div>
    )
}