import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./productCard.css";
import { ButtonUnderLine } from "../../../button/button";

export const Productcard = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = `https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${props.artwork}&amp;cache-control=max-age=172800`;
    image.onload = () => {setTimeout(() => {
        setImageLoaded(true)
    }, 0)};
  }, [props.artwork]);

  return (
    <Link to="/product" state={{ info: props.info }} className="product">
      {!imageLoaded ? (
        <div className="skeleton-image"></div>
      ) : (
        <img
          className="image"
          alt="Image"
          src={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${props.artwork}&amp;cache-control=max-age=172800`}
        />
      )}
      <div className="text-wrap">
        <p className="palak-and-dharmesh">
          <span className="span">
            {props.title}
            <br />
          </span>
          {/* <span className=" text-wrapper">{props.category}</span> */}
        </p>
        <ButtonUnderLine text={'Learn More'} arraow={true}/>
        {/* <div className="learn-more">
          <div className="div">Learn More</div>
          <img className="arrow-outward" alt="Arrow outward" src="arrow_outward.svg" />
        </div> */}
      </div>
    </Link>
  );
};

