import { useEffect, useState } from "react";
import { MainHeading } from "../../component/headings/heading";
import Popup from "../../component/popup/popup";
import { Loadin } from "../../component/login/success";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
export const B2BOrdersummry = ({isSample=false,sample={}}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const plan = isSample?sample.id:queryParams.get("plan");
  const category = queryParams.get("category");
  const categoryplan = queryParams.get("categoryname");
  const subcategoryplan = queryParams.get("subcategoryname");
  const [data, setData] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  console.log(sample.data)


  useEffect(() => {
    setData(null)
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}api/B2Bpricing`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        for (let i = 0; i < data.pricingData.length; i++) {
          if (data.pricingData[i]._id === plan) {
            setData(data.pricingData[i]);
            console.log(data.pricingData[i])
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  
  const pay = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({ paymentID:plan,couponCode:couponCode }),
        credentials: 'include',
      };
      
      fetch(process.env.REACT_APP_API_URL + "api/payment", requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if(response.status == 201){
            window.location = '/login'
          }
           else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
            window.location = data.url   
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error("Error sending payment ID: " + error);
        });
  }catch(e){
    console.log(e)
  };
}

const paySample = async () => {
  const apiUrl = process.env.REACT_APP_API_URL + "api/paymentForSample";
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sample.data),
      credentials: 'include',
    };

    fetch(apiUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 201) {
          window.location = '/login';
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        window.location = data.url;
      })
      .catch(error => {
        console.error("Error sending payment ID: " + error);
      });
}catch(e){
  console.log(e)
};
}
  
  const applyCoupon = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/applyCoupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          couponCode,
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        setShowPopup(true)
      }

      const couponData = await response.json();

      // Check if couponData has the expected structure before accessing its properties.
      if (couponData && couponData.valid && couponData.discount) {
        setAppliedCoupon(couponData);
        setShowPopup(true)
        set_popupheading('Yaay! Coupon Applied')
        set_popuptext(couponData.title)
        setTimeout(() => {
          setShowPopup(false)
            
        }, 2500);
      } else {
        console.error('Invalid coupon data:', couponData);
        setShowPopup(true)
        set_popupheading('Coupon In-valid')
        set_popuptext('This coupon might be expired or it is invalid.')
        // Handle invalid coupon as needed
      }
    } catch (error) {
      console.error('Error during coupon fetch:', error);
      setShowPopup(true)
      
      // Handle the error as needed
    }
  };


  const calculateTotal = () => {
    const deliveryCharge = isSample?70:0;
    const magazinePrice = data.price*(data.perMagazine || sample.quanttity);
    let total = magazinePrice + deliveryCharge;
    const maxDiscount = magazinePrice*data.perMagazine
    if (appliedCoupon && appliedCoupon.valid) {
      const discountedTotal = Math.min(appliedCoupon.discount, maxDiscount);
      total -= discountedTotal
    }
  
    return total;
  };
  
  const calculatePayableAmount = () => {
      return calculateTotal();
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popuptext,set_popuptext] = useState('Something Went Wrong')
  const [popupheading,set_popupheading] = useState('Sorry')
  
  return data !== null ? (
    <>
    <div className="body order-summry" style={{marginTop:'0px',padding:'0',flexDirection:'column',flexWrap:'nowrap'}}>
      <div className="hide-desktop" style={{width:'100%'}}>
      <MainHeading name="Order Summary" />

      </div>
      <Popup
      show={showPopup}
      heading={popupheading}
      message={popuptext}
      isNotification={true}
      onConfirmPopup={(result) => {
        setShowPopup(false);
      }}
    />
    <div className="left" style={{width:'100%'}}>
    <div className="cont">
        <img className="img" src="/physical_sample.jpg" alt="Magazine Cover" />
        <div className="text-wrap">
          <div className="heading">{data.name.split(' ')[1]} Editions</div>
          <div className="sub-heading" style={{textTransform:'none'}}>{data.perMagazine?`${data.perMagazine} iCredits`:'Non-customized'}</div>
        </div>
      </div>

      <div className="bill">
        <div className="cont">
          <div className="svg-text">
            <img className="svg" src="/magazineBlack.svg" alt="Magazine Icon" />
            <div className="text" style={{textTransform:'capitalize'}}>{data.name} {`(₹${data.price})`}</div>
            <div className="text" > &nbsp; &nbsp; &nbsp;X {data.perMagazine || sample.quanttity}</div>
          </div>
          <div className="price">₹ {data.price*(data.perMagazine || sample.quanttity)}</div>
        </div>

        {isSample?<div className="cont">
          <div className="svg-text">
            <div className="text">Delivery</div>
          </div>
          <div className="price">₹ 70</div>
        </div>:null}

        {appliedCoupon && appliedCoupon.valid && (
          <div className="cont">
          <div className="svg-text">
            <div className="text">Discount Applied</div>
          </div>
          <div className="price">- ₹ {appliedCoupon.discount}</div>
        </div>
        )}

        <div className="total">
          <span>Total</span>
          <span>₹ {calculateTotal()}</span>
        </div>

         {/* {appliedCoupon && appliedCoupon.discount > data.price  && (
          <div className="total" style={{ color: 'var(--persian-red)', border: 'none', padding: '0' }}>
            <span>Max Discount</span>
            <span>₹ {data.price}</span>
          </div>
        )}  */}

    </div>
    </div>
    <div className="right" style={{width:'100%',margin:'auto'}}>
      <div className="coupan-cont">
      <div className="heading">Nostalgic Deals</div>
      <div className="input-cont">
        <input
          type="text"
          className="cupanCode"
          placeholder="APPLY COUPON"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <div className="but" onClick={applyCoupon}>
          Apply
        </div>
      </div>
      {appliedCoupon && appliedCoupon.valid && (
        <div className="applied-coupon">
          Applied Coupon: - ₹ {appliedCoupon.discount}
        </div>
      )}
    </div>
    <div
        className="proceed-button"
        id = "proceed-button-b"
        style={{display:'flex'}}
        onClick={() => {
          pay();
          // Disable pointer events for 5 seconds
          document.querySelector('#proceed-button-b').style.pointerEvents = 'none';
          document.querySelector('#proceed-button-b').style.backgroundColor = '#c0564c';
          setTimeout(() => {
            document.querySelector('#proceed-button-b').style.pointerEvents = 'auto';
            document.querySelector('#proceed-button-b').style.backgroundColor = 'var(--persian-red)';
          }, 7000);
        }}
      >
        Pay ₹ {calculatePayableAmount()}
      </div>
      </div>
      </div>
    
     
   </>
  ) : (
    <Loadin />
  );
};