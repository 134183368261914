import React from 'react'
import { ButtonPrimary, ButtonPrimarySmall, ButtonSecondary, ButtonUnderLine } from '../../component/button/button'
import { Helmet } from 'react-helmet';
import './test1.css'
import { Banner } from '../../dashboard/home/home'
import { NavLink } from 'react-router-dom'
export default function Community() {
  return (

    <div className='body' style={{minHeight:'100%',marginTop:'50px'}}>
      <Helmet>
            <title>Login/Sign-up</title>
    </Helmet>
    <div className='body-dynamic'>
    <div  style={{ backgroundImage:"url('/banner/login_banner.png')",backgroundSize: 'cover', backgroundRepeat: 'no-repeat',aspectRatio:'1/1',borderRadius:'var(--br-1)',width:'100%',maxWidth:'520px' }}></div>

  
<div style={{ display: 'flex', justifyContent: 'center' }}>
<div className="community" style={{ width: '90%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column',gap:'30px'}}>
<p className="looks-like-you-are">
  Looks like you are not a part of our community yet.</p>

<NavLink to="/login" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <ButtonPrimary text={'Login/Sign-Up'} />
</NavLink >
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<img style={{ height: "17px",width: "17px"  }} className="orders" alt="Orders" src="Union.svg" />
  
</div>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

<NavLink to="/PartnerLogin" >
  <ButtonUnderLine text="Become a Partner"/>
</NavLink>
  
</div>

</div>
</div>
    </div>
    

  </div>
  
   
  )
}
