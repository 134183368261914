import React from 'react'
import './test.css'
import { NavLink } from 'react-router-dom'
import { ButtonUnderLine } from '../button/button'
export default function Ordercheck(props) {
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column',gap:'13px'  }}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img style={{ height: "58px",width: "57px"  }} className="orders" alt="Orders" src="orders.svg" />
    </div>
    <div ><p className='ordercheck2'>{props.heading||"You haven't started investing in memories yet."}</p></div>
    <div>
  <NavLink to = {props.direct || "/orderD2C"} className='ordercheck'>
    <ButtonUnderLine style={{fontSize:'14px'}} arraow={true} text={props.button||"Get Started"} />
  </NavLink>
  
</div>

</div>

  )
}
