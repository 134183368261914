import PropTypes from "prop-types";
import React, { useState } from "react";
import "./button1.css";
import {useNavigate} from 'react-router-dom';
import Popup from "../popup/popup";
import { Corporate } from "../plans/corporate/corporate";
export const ButtonPrimary = (props) => {
const [popupshow,setShowPopUp] = useState(false)
  const navigate = useNavigate();
const getstarted=()=>
{


  const paymentID = props.plan.id;
  const value = props.value
  const apiUrl = process.env.REACT_APP_API_URL + "api/payment";
  
  

if(props.plan.price!=='-')
{
  navigate(`/summry?plan=${paymentID}`)
  return;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({ paymentID,value }),
    credentials: 'include',
  };
  
  fetch(apiUrl, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if(response.status == 201){
        window.location = '/login'
      }
       else {
        throw new Error('Network response was not ok');
      }
    })
    .then(data => {
        window.location = data.url   
    })
    .catch(error => {
      // Handle any errors that occur during the request
      console.error("Error sending payment ID: " + error);
    });}
else{
  setShowPopUp(true)
}
  
}

  return (
    <>
<Popup
heading="Please select a bundle"
isNotification={true}
message={<Corporate bundles={props.bundles} onMagazineSelect={props.onMagazineSelect} heading={true}/>}
onConfirmPopup={()=>{
  setShowPopUp(false)
}}
show={popupshow}
></Popup>
<div className={`button-primary-price`}  onClick={getstarted}>
      <div className="text-wrapper">Get Started</div>
    </div>
    </>
    
  );
};


ButtonPrimary.propTypes = {
    text: PropTypes.string,
};
