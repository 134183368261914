import PropTypes from "prop-types";
import React from "react";
import "./button.css";
import { NavLink } from "react-router-dom";

export const ButtonSecondary = ({ className, customStyle = {}, text, direction, to, onClick }) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <NavLink to={direction ? to : ''} className={`button-secondary ${className}`} style={customStyle} onClick={handleClick}>
      <div className="text-wrapper">{text || "order now"}</div>
    </NavLink>
  );
};

export const ButtonPrimary = ({ className, customStyle, text, direction, to, onClick }) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      {direction ? (
        <NavLink to={to} className={`button-primary ${className}`} style={customStyle} onClick={handleClick}>
          <div className="text-wrapper">{text || "order now"}</div>
        </NavLink>
      ) : (
        <div className={`button-primary ${className}`} style={customStyle} onClick={handleClick}>
          <div className="text-wrapper">{text || "order now"}</div>
        </div>
      )}
    </>
  );
};


export const ButtonPrimarySmall = ({text}) => {
  return (
      <div className="button-primary-small">
          <div className="text-wrapper">{text}</div>
      </div>
  );
}

export const ButtonUnderLine = ({text,arraow=false,style={}})=>{
  return(
    <div style={{borderBottom:`${arraow?'none':'0.5px solid var(--jet-black)'}`,margin:`${arraow?'0':'auto'}`}} className="button-underline">
      <div className="inner-text" style={style}>{text}</div>
      {arraow?<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="none">
        <mask id="mask0_555_2559" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect x="0.24707" y="0.415039" width="14" height="14" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_555_2559)">
        <path d="M8.00096 7.41501L5.31763 4.73167L5.73045 4.31885L8.82661 7.41501L5.73045 10.5112L5.31763 10.0983L8.00096 7.41501Z" fill="#595958"/>
        </g>
      </svg>:null}
    </div>
  )
}

ButtonSecondary.propTypes = {
    text: PropTypes.string,
};
