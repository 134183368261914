import React, { useState } from 'react';
import './popup.css'
import { ButtonPrimary, ButtonSecondary } from '../button/button';
import { HedingSubheding } from '../headings/heading';
import { Banner } from '../../dashboard/home/home';

const Popup = ({heading, message, isNotification, show, onConfirmPopup,ACCEPT,DENY,DEF_OK }) => {
  if (!show) {
    return null;
  }

  function onConfirm(value) {
    if (onConfirmPopup) {
      onConfirmPopup(value); // Call the provided onConfirmPopup function
    }
  }

  return (
    <div className="popup-cont">
      <div className="popup">
      <div className="heading">{heading}</div>
      {message && <div className="text">{message}</div>}
        {isNotification ? (
          <div className="accept-wrapper pointer" onClick={() => onConfirm(true)}>
            <div className="accept">{DEF_OK||'OK'}</div>
          </div>
        ) : (
          <div className="popup-frame">
            <div className="button-mobile pointer" onClick={() => onConfirm(false)}>
              <div className="text-wrapper">{DENY||'DENY'}</div>
            </div>
            <div  className="accept-wrapper pointer" onClick={() => onConfirm(true)}>
              <div className="accept">{ACCEPT||'ACCEPT'}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const PopUpBanner = ({heading, message, button, show, onConfirmPopup,ACCEPT,DENY,direction,customStyle }) => {
  if (!show) {
    return null;
  }

  function onConfirm(value) {
    if (onConfirmPopup) {
      onConfirmPopup(value); // Call the provided onConfirmPopup function
    }
  }

  return (
    <div className="popup-cont" onClick={() => onConfirm(true)}>
      <Banner
        style={customStyle}
        heading={heading}
        sub_heading={message}
        url="/banner/valentines.png"
        but={true}
        close={true}
        button={button}
        direction={direction}
        closeFunc={() => onConfirm(true)}
      />
    </div>
  );
};





const TrackText = (props)=>{
  return(
    <div className='track-text-cont'>
      <img className='icon' src={props.icon} alt='icon' />
      {props.connector && <div className='connector' ></div>}
      <div className='text-wraper'>
        <div className='heading'>{props.heading}</div>
        <div className='sub-heading'>{props.subHeading}</div>
      </div>
    </div>
  )
}

export const PopupBottom = ({heading, message, isNotification, show, onConfirmPopup,ACCEPT,DENY,DEF_OK }) => {
  if (!show) {
    return null;
  }

  function onConfirm(value) {
    if (onConfirmPopup) {
      onConfirmPopup(value); // Call the provided onConfirmPopup function
    }
  }

  return (
    <div className="popup-bottom-cont">
      <div className="popup-bottom">
      <div className="heading" >{heading}</div>
      <TrackText
        connector={true}
        icon='/icons/success.svg'
        heading='Order Confirmation'
        subHeading='To initiate the process, we just require 50% of the payment to confirm the order and generate your exclusive form.'
      />
      <TrackText
        connector={true}
        icon='/icons/form.svg'
        heading='Secret form'
        subHeading={`We'll send over an exclusive imurs form where you can share your memories and images with us.`}
      />
      <TrackText
        connector={true}
        icon='/icons/soft-copy.svg'
        heading='Soft Copy Approval'
        subHeading={`Your nod matters! Once the digital draft is ready, we'll send it your way for approval`}
      />
      <TrackText
        icon='/icons/print-2.svg'
        heading='Printing and Shipping'
        subHeading={`With your green light, we'll bring your edition to life. From printing to delivery, every step is handled with care.`}
      />
        {isNotification ? (
          <ButtonPrimary text={'Proceed'} onClick={() => onConfirm(true)} customStyle={{marginTop:'10px'}}/>
        ) : (
          <div className="popup-frame">
            <div className="button-mobile pointer" onClick={() => onConfirm(false)}>
              <div className="text-wrapper">{DENY||'DENY'}</div>
            </div>
            <div  className="accept-wrapper pointer" onClick={() => onConfirm(true)}>
              <div className="accept">{ACCEPT||'ACCEPT'}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


const FormGroup = (props) => (
  <div className="form-group">
    <div className="lable">
      {props.sequence !== "" ? <span className="sequence">{props.sequence}</span> : null}
      {props.label}
    </div>
    {props.inputType !== 'none' && (
      <input
        type={props.inputType}
        name={props.name}
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    )}
  </div>
);

export const PopupBottomAddress = ({heading, address, isNotification, show, onConfirmPopup,ACCEPT,DENY,data }) => {
  console.log(data)
  if(!data.addresses){
    data['addresses'] = {}
  }
  const [formData, setFormData] = useState({
    name: data.name,
    zip: data.addresses.length > 0 ? data.addresses[0]['postalCode'] : '',
    address: data.addresses.length > 0 ? data.addresses[0]['street'] : '',
    city: data.addresses.length > 0 ? data.addresses[0]['city'] : '',
    email: data.email,
  });


  if (!show) {
    return null;
  }

  function onConfirm(value) {
    if (onConfirmPopup) {
      onConfirmPopup(value); // Call the provided onConfirmPopup function
    }
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <div className="popup-bottom-cont">
      <div className="popup-bottom">
        <img src='/icons/location.svg' alt='location'></img>
      <div className="heading" >{heading}</div>
      <div className='form' style={{width:'100%'}}> 
      <FormGroup
            
            sequence=""
            label="*Address "
            inputType="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            id="address"
            placeholder='Eg: Sopha Indraprashtha, 17th Floor, HSR Layout'
          />

          <div className="g2">
            <FormGroup
              
              sequence=""
              label="*City "
              inputType="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              id="city"
              placeholder="Eg: Bengaluru"
            />
             <FormGroup
              
              sequence=""
              label="*Zip Code"
              inputType="text"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              id="zip"
              placeholder='Eg: 344713'
            />
          </div>
       </div>

        {isNotification ? (
          <ButtonPrimary text={'Proceed'} onClick={() => onConfirm(formData)} customStyle={{marginTop:'10px'}}/>
        ) : (
          <div className="popup-frame">
            <div className="button-mobile pointer" onClick={() => onConfirm(false)}>
              <div className="text-wrapper">{DENY||'DENY'}</div>
            </div>
            <div  className="accept-wrapper pointer" onClick={() => onConfirm(true)}>
              <div className="accept">{ACCEPT||'ACCEPT'}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Popup;
