import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HedingSubhedingSimple } from '../headings/heading';
import FlipbookPreview from '../dearFlip/dearFlip2';
import { Loadin } from '../login/success';
import { ButtonPrimary, ButtonSecondary } from '../button/button';
import Popup from '../popup/popup';
import "./preview.css"
import { customTitleCase } from '../pricingD2c/card/pricingD2C';
export const PreviewWindowQR = () => {
  const { edition } = useParams();
  const [editionInfo, setEditionInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEditionInfo = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + 'api/editions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ edition }),
        });

        const data = await response.json();
        setEditionInfo(data);

        if (data.orderStatus < 6 && data.orderStatus > 3) {
          window.location.href = process.env.REACT_APP_API_URL + "editor/addTrackingLink/" + edition;
          return;
        }
        
        // Assuming data.trackingLink.lastUpdate is the Date object representing the last update
        const lastUpdateDate = new Date(data.trackLink.lastUpdate);
        const currentDate = new Date();
        
        const timeDifferenceInMilliseconds = currentDate - lastUpdateDate;
        const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);
        console.log(timeDifferenceInHours)
        if (timeDifferenceInHours < 16) {
          // Redirect or perform some action if the last update is less than 16 hours ago
          window.location.href = process.env.REACT_APP_API_URL + "editor/addTrackingLink/" + edition;
          return
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching edition information:', error);
        setLoading(false);
      }
    };

    fetchEditionInfo();
  }, [edition]);

  const [popupText,setPopUpText]= useState("");
  const [popup,setPopup]= useState(false);

  const handleRequestRevision = async () => {
    try {
      setPopUpText("Revision Accepted !")
      setPopup(true)
      editionInfo.live = 2

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/request-revision`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          edition:editionInfo._id

        }),
      });
  
      const data = await response.json();
  
      // Handle the response as needed
      console.log('Request Revision Response:', data);
  
      // You can also trigger additional actions based on the response
      // For example, show a success message or update the UI
    } catch (error) {
      console.error('Error requesting revision:', error);
  
      // Handle errors, show an error message, etc.
    } 
  };
  
  const handleApprove = async () => {
    setPopUpText("Edition Approved!")
    setPopup(true)
    try {
      editionInfo.live = 3
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          edition:editionInfo._id
        }),
      });
   
      const data = await response.json();
      window.location.href = '/tracking/'+edition
      // Handle the response as needed
      console.log('Approve Response:', data);
  
      // You can also trigger additional actions based on the response
      // For example, show a success message or update the UI
    } catch (error) {
      console.error('Error approving:', error);
  
      // Handle errors, show an error message, etc.
    }
  };
  
  return (
    <>
      <Popup
      isNotification={true}
      show={popup}
      heading={popupText}
      onConfirmPopup={()=>{setPopup(false)}}
      ></Popup>
      {loading ? (
        <Loadin />
      ) : (
        <>
          <HedingSubhedingSimple heading={`${customTitleCase(editionInfo.customerName)}'s Edition`} sub_heading={editionInfo.live < 3 ? (editionInfo.live === 1 ? "Review & Approval Page" : "Under Revision") : ("Soft Copy")} />
          <div style={{marginTop:'-40px'}}>
          <FlipbookPreview source={editionInfo.pdf[0]} />
          </div>
          {(editionInfo.pdf.length && editionInfo.live < 2) ? (
            <div className='bottom-buttons' style={{ width: '100%', display: 'flex', gap: '20px', maxWidth: '600px' }}>
              <ButtonSecondary customStyle={{ minWidth: '55%' }} text="Request Revision" onClick={handleRequestRevision} />
              <ButtonPrimary customStyle={{ minWidth: '0px' }} text="Approve" onClick={handleApprove} />
            </div>
          ) : null}
        </>
      )}
    </>
  );
  

};

export default PreviewWindowQR;
