import React from 'react'
import { Dashboard } from '../../dashboard/structure/structure'
import  { ProductB2B } from './product'

export default function Productdash(props) {
 
  return (
  
    <Dashboard data={props.data}>
    <ProductB2B isnew={props.data.isnew}/>
    </Dashboard>
    
  )
}
