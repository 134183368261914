import React, { useEffect, useState } from 'react';
import { Productcard } from './productCard/productCard';
import './product-grid.css';
import { Loadin } from '../../login/success';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { EffectCoverflow, Mousewheel } from 'swiper/modules';
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProductGrid(props) {
  const [products, setProducts] = useState([]);
  const query = useQuery();
  const display = query.get('display');
  const infoValue = display ? props.info.filter((obj)=>obj.display[display]) : props.info;
  const flag = props.flag;
  useEffect(() => {
    if (infoValue) {
      
      const mappedProducts = infoValue.map((item) => ({
        id: item._id,
        title: item.title,
        direction: props.direction,
        thumbnail: item.thumbnail,
        category: item.category,
        custmerCity: item.custmerCity,
        custmerName: item.custmerName,
        customerImg: item.customerImg,
        discription: item.discription,
        feedBack: item.feedBack,
        feedBackTitle: item.feedBackTitle,
        pdf: item.pdf,
        images: item.images,
        bullets: item.bullets,
        display: item.display,
      }));

      setProducts(mappedProducts);
    }
  }, [infoValue]);

  

  // Number of items to display per page
  let itemsPerPage;
  if (window.innerWidth > 768) {
    // Set a different value for desktop (greater than 768px)
    itemsPerPage = 4; // For desktop
  } else {
    // Set a different value for mobile (768px or less)
    itemsPerPage = 2; // For mobile
  }
  // Current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Get the current items to display
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

  // Total number of pages
  const totalPages = Math.ceil(products.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  

console.log(products)
  return (
    products.length>0?(flag===0?<div className="container" >
         
           {window.innerWidth > 768 && (
        <div className="row">
          {currentItems.map((product) => (
            <div className="col-6 col-sm-4" key={product.id}>
               <Productcard 
                direction={props.direction} artwork={product.thumbnail} info={product} category={product.category} title={product.title}/>
            </div>
          ))}
        </div>
      )}

    
      {window.innerWidth <= 768 && (
        <div className="row">
          {currentItems.map((product) => (
            <div className="col-6 col-sm-4" key={product.id}>
               <Productcard 
                direction={props.direction} artwork={product.thumbnail} info={product} category={product.category} title={product.title}/>
            </div>
          ))}
        </div>
      )}
    
      <div className="mt-5" style={{ display: "flex", justifyContent: "center" }}>
        <nav aria-label="Pagination">
          <ul className="pagination" style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a style={{backgroundColor:'transparent',border:'none',color:'var(--jet-black)'}} 
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
              </a>
            </li>
            <span className='prod-grid-pageing'>
            {currentPage + ' / ' + totalPages}
            </span>
            
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <a  style={{backgroundColor:'transparent',border:'none',color:'var(--jet-black)'}}
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}

              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>:(
      <div className='sample-cont' style={{maxWidth:'1080px',margin:'auto'}}>
{products.map((product) => (
            <div className="col-6 col-sm-4" key={product.id}>
               <Productcard artwork={product.thumbnail} info={product} category={product.category} title={product.title}/>
            </div>
          ))}
      </div>)
    ):<Loadin/>
  );
}

export const ProductSwiper = (props)=>{
  const [products, setProducts] = useState([]);
  const infoValue = props.info; // Store props.info in a constant variable
  
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 540) {
        setSlidesPerView(3); // Set to 1 slide per view for mobile
      }else if (window.innerWidth < 720) {
        setSlidesPerView(4); // Set to 1 slide per view for mobile
      } else {
        setSlidesPerView(5); // Set to 3 slides per view for larger screens
      }
    };

    handleResize(); // Set initial state based on window width
    window.addEventListener('resize', handleResize); // Update state on window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on component unmount
    };
  });

  const flag = props.flag;
  useEffect(() => {
    if (infoValue) {
      const mappedProducts = infoValue.map((item) => ({
        id: item._id,
        title: item.title,
        direction:props.direction,
        thumbnail: item.thumbnail,
        category: item.category,
        custmerCity: item.custmerCity,
        custmerName: item.custmerName,
        customerImg: item.customerImg,
        discription: item.discription,
        feedBack: item.feedBack,
        feedBackTitle: item.feedBackTitle,
        pdf: item.pdf,
        images: item.images,
        bullets: item.bullets
      }));
      setProducts(mappedProducts);
    }
  }, [infoValue]);
  return(
    <>
    <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: false,
        }}
        className="mySwiper"
        style={{padding:'0px',marginLeft:props.fullscreen?'-20px':'',width:props.fullscreen?'calc(100% + 40px)':''}}
        grabCursor={true}
            coverflowEffect={{
              rotate: 5,
              stretch: 0,
              depth: 10,
              modifier: 1,
            }}
            mousewheel={{
              forceToAxis: true,
              releaseOnEdges: true,
              sensitivity: 1,
            }}
            modules={[FreeMode,Mousewheel]}
            
      >
    {products.map((product) => (
          // Conditionally render ProductCard based on selectedCats (case-insensitive)
          (props.selectedCats?props.selectedCats.includes(product.category.toLowerCase()):1) && (
            <SwiperSlide key={product.id}>
              <Productcard
                artwork={product.thumbnail}
                info={product}
                category={product.category}
                title={product.title}
                direction={props.direction}
              />
            </SwiperSlide>
          )
        ))}
    
      </Swiper>
    </>
  )
}

 