import React, { useEffect } from "react";
import { ButtonSecondary, ButtonUnderLine } from "../../../button/button";
import "./decorCard.css";
import { NavLink } from "react-router-dom";

export const DecorCard = () => {
  useEffect(() => {
    // Auto-click the "jugad" button after 3 seconds
    
    
      setTimeout(() => {
        const jugadButton = document.getElementById("auto-move-jugad-decor");
        if (jugadButton) {
        jugadButton.click();
      }
      }, 3000);
    
  }, []);
    return (
        <>
        <div className="container-desktop">
            <div className="decor-card">
                <div className="img-cont">
                    <img className="img" alt="Img" src="Living_Room.png" />
                </div>
                <div className="cont">
                    <div className="headin">
                        <div className="catigory">Harshita's Coffee Table</div>
                    </div>
                    <div className="text-wrap">
                        <p className="text">
                        Snippets of Mahi's Love story bloom on her coffee table, whispering tales of "every reason" and one perfect "yes” for this love story.
                        </p>
                        <NavLink to={"/plans"}><ButtonUnderLine text={"Create Order"}/></NavLink>
                    </div>
                </div>
            </div>
            <div className="decor-card">
                <div className="img-cont">
                    <img className="img" alt="Img" src="Bed_Room.png" />
                </div>
                <div className="cont">
                    <div className="headin">
                        <div className="catigory">Mahi's Bedside Table</div>
                    </div>
                    <div className="text-wrap">
                        <p className="text">
                        Each glance before sleep is a nostalgic journey back to vows, intimate moments, and the sweet sting of happy tears from her D-Day.
                        </p>
                       
                        <NavLink to={"/plans"}> <ButtonUnderLine text={"Create Order"}/></NavLink>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-mobile">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" id="auto-move-jugad-decor" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active" aria-current="true" aria-label="SlideDecor 1" style={{ filter: "brightness(0%)", width: "18px", height: "2px",borderRadius:'21px' }}></button>
    <button type="button"  data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="SlideDecor 2" style={{ filter: "brightness(0%)", width: "18px", height: "2px",borderRadius:'21px' }}></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="6000">
      <div className="decor-card">
        <div className="img-cont">
          <img className="img" alt="Img" src="Living_Room.png" />
        </div>
        <div className="cont">
          <div className="headin">
            <div className="catigory">Harshita's Coffee Table</div>
          </div>
          <div className="text-wrap">
            <p className="text">
            Snippets of Mahi's Love story bloom on her coffee table, whispering tales of "every reason" and one perfect "yes” for this love story.
            </p>
            <NavLink to={"/plans"}> <ButtonUnderLine text={'Create Order'} /></NavLink>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="6500">
      <div className="decor-card">
        <div className="img-cont">
          <img className="img" alt="Img" src="Bed_Room.png" />
        </div>
        <div className="cont">
          <div className="headin">
            <div className="catigory">Mahi's Bedside Table</div>
          </div>
          <div className="text-wrap">
            <p className="text">
            Each glance before sleep is a nostalgic journey back to vows, intimate moments, and the sweet sting of happy tears from her D-Day.
            </p>
            <NavLink to={"/plans"}> <ButtonUnderLine text={'Create Order'} /></NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
        </>
        
        
    );
};
