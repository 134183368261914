import React, { useEffect, useState } from "react";
import "./home.css";

import { InfiniteMarquee, RegistrationMarquee, WelcomMarquee } from "../marquee/marquee";
import { FlipbookContainer } from "../flipBookShow/flipBookShow";
import { VideoBackground } from "../displayVideo/video";
import { PartnerInvite } from "../partnerInvite/partnerInvite";

import { DecorCard } from "../product/cards/decorCard/decorCard";
import { ProcessContainer } from "../product/cards/processCard/processCard";
import ProductGrid, { ProductSwiper } from "../product/product-grid/product-grid";
import { MainHeading } from "../headings/heading";
import Carousel from "../feedback/courosal/courosal/courosal";
import Courosal from "../feedback/courosal/courosal/courosal";
import { PopUpTest } from "../popup/test";
import { FeatureCard, FeatureWindow } from "../exploremore/explore";
import { BackGround, ScrollAnimation } from "../3dAnime/threeanimation";
import { SuccessV2 } from "../login/success";
import { NavLink } from "react-router-dom";
import { FaQ } from "../footer/FaQs/FaQs";
import { PopUpBanner } from "../popup/popup";

export const Home = () => {
  const [info, setInfo] = useState(null);
  const [faqOn,setFaQon] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/product")
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the 'info' state variable

        setInfo(data.product);
        console.log(data.product);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  useEffect(() => {
    const handlePopState = () => {
        console.log('back');
        setFaQon(!faqOn);
        // process
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
        window.removeEventListener('popstate', handlePopState);
    };
}, [faqOn]);

const [popup,setPopup] = useState(false);

const valentinesNotify = () => {
  // Check if the popup has been shown before in the session storage
  const hasPopupBeenShown = sessionStorage.getItem('popupShown');

  // If not shown before, set a timeout to show the popup after 5 seconds
  if (!hasPopupBeenShown) {
    const timeoutId = setTimeout(() => {
      setPopup(true);
      sessionStorage.setItem('popupShown',true);
    }, 4300);

    // Clear the timeout if the component is unmounted before 5 seconds
    return () => clearTimeout(timeoutId);
  }

  // If the popup has been shown before, setPopup to false
};


    return (
    <>
      <div className="loco-body"/>

    <div
      className="body negative-space"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor:'var(--isabeline)',
       
      }}
    >
      {/* <VideoBackground /> */}

      <PopUpBanner
      isNotification={true}
      show={popup && false}
      customStyle={{aspectRatio:'720/506',maxWidth:'80%',width:'620px'}}
      onConfirmPopup={()=>{setPopup(false)}}
      direction={'/valentines'}
      heading={'Introducing Heartstrings'}
      message = {"A limited valentines edition series"}
      button={'Explore Heartstrings Edition'}
      ></PopUpBanner>
      
      <ScrollAnimation valentinesNotify={valentinesNotify} int={148}/>
      {/* <RegistrationMarquee/> */}
      <div></div>

      <WelcomMarquee/>

      <div className="cardsspacing" >
        {" "}
        <MainHeading name="A Sentimenal Home Decor" />
        <DecorCard />
      </div>
 
      <ProcessContainer />

      {/* <FlipbookContainer source={"shivani.pdf"} /> */}
     
      <div className="cardsspacing">
        {" "}
        <MainHeading name="Imurs’ Greatest Hits" />
        {window.innerWidth > 768?<ProductGrid info={info} flag={0}/>:<ProductSwiper info={info} flag={0}/>}
      </div>


      

      <div className="cardsspacing" >
      <MainHeading name="Crafted with Care"/>
      <FeatureWindow/>
      </div>

      <Courosal />
      
      
      <PartnerInvite />

      <a href='/#' className="create-order-fix" id="faq-button" style={{transform:'scale(0)',fill:'#fff'}}>
    {faqOn?(<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M291-267.692 267.692-291l189-189-189-189L291-692.308l189 189 189-189L692.308-669l-189 189 189 189L669-267.692l-189-189-189 189Z"/></svg>):(<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M457.231-378.461q0-45.847 16.807-82.501 16.808-36.653 68.193-80.5 38.384-33.923 50.307-59.5 11.923-25.576 11.923-56.653 0-50.308-36.23-83.269-36.231-32.962-92.846-32.962-37.77 0-70 16.077-32.231 16.077-51.923 59.923L317.461-714q25.616-52.923 68.039-75.615 42.423-22.693 89.885-22.693 72.846 0 120.384 43.808 47.539 43.808 47.539 110.962 0 35.846-16.192 71.115-16.193 35.269-54.962 67.962-47.154 39.538-60.5 68.192-13.346 28.654-14.346 71.808h-40.077ZM475.385-184q-13.077 0-22.539-9.461-9.462-9.462-9.462-22.539 0-13.077 9.462-22.539Q462.308-248 475.385-248q13.077 0 22.538 9.461 9.462 9.462 9.462 22.539 0 13.077-9.462 22.539Q488.462-184 475.385-184Z"/></svg>)}
</a>


    <FaQ hidden={faqOn?false:true}/>

    </div>
    </>
    
  );
};
