import { useEffect, useState } from 'react'
import { BannerV2, BannerV3 } from '../../dashboard/home/home'
import { IconDiscriptin, MainHeading, SimpleHeading } from '../headings/heading'
import { RegistrationMarquee, WhiteMarquee } from '../marquee/marquee'
import ProductGrid, { ProductSwiper } from '../product/product-grid/product-grid'
import './valentine.css'
import { ExpandQuestion } from '../footer/FaQs/FaQs'
import { Loadin } from '../login/success'
import { useParams } from 'react-router-dom';

export const Valentine = ()=>{
    const [info, setInfo] = useState(null);
    useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + "api/product")
        .then((response) => response.json())
        .then((data) => {
          // Store the fetched data in the 'info' state variable
  
          setInfo(data.product);
          console.log(data.product);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);
    return(
        <>
         <BannerV3 url="/banner/valentines_banner.png" 
         customStyle={{aspectRatio:'1/1'}}
        heading={'Heartstrings x Valentines’ Edition '} 
        sub_heading_2 = {`A testament to your love story, a reminder of the unwavering connection that binds you. Dive into heartfelt narratives penned just for you and rediscover the magic that brought you together.`} 
        sub_heading = {<span style={{fontFamily:'HK Grotesk-SemiBold',color:'var(--jet-black-2)'}}>Starts at ₹1449</span>} 
        button={'Get your Heartstrings Edition'}
        direction = '/selectPlan?category=65c0da1da8ba8daa251bbc6f&categoryname=Couple&subcategoryname=heartstrings%20x%20valentines'/>

        <IconDiscriptin words = {
            [
                {
                    icon:'/product_icons/2.svg',
                    text:'Commencement of relationship'
                },
                {
                    icon:'/product_icons/12.svg',
                    text:'Love Story Highlights'
                },
                {
                    icon:'/product_icons/32.svg',
                    text:'Favorite Moments Together'
                },
                {
                    icon:'/product_icons/3.svg',
                    text:'Significant Milestones'
                },
                {
                    icon:'/product_icons/9.svg',
                    text:'Promises and Commitments'
                },
                {
                    icon:'/product_icons/20.svg',
                    text:'Visions of Forever'
                },
                
            ]
        }
        heading ={`What will be inside this edition?`} />



        <WhiteMarquee words={['A 100% Customized Edition',
        'Chic and Aesthetic Design',
        'Personalized content written from scratch',
        'Classic Typography',
        'Quirky, witty and hilarious captions']}
        style={
            {width:'calc(100% + 40px)',marginLeft:'-20px'}
        }/>
        



        <div className="cardsspacing" >
        {" "}      
            <SimpleHeading text="Explore Couple Editions" customStyle = {{fontFamily:'HK Grotesk-Medium',fontWeight:'500'}}/>
            <ProductSwiper 
            fullscreen={true}
            selectedCats={['significant milestones edition','courtship whispers and vows edition']} 
            direction='/selectPlan?category=65c0da1da8ba8daa251bbc6f&categoryname=Couple&subcategoryname=heartstrings%20x%20valentines' 
            info={info} 
            flag={0}/>
      </div>




      <div className="cardsspacing" >
        <SimpleHeading text={"FAQs that might help"}/>
        <div className={"faq-body"} style={{position:'relative',padding:'0'}} >
            <div className='faq-cont-small'>
            <ExpandQuestion
  question="How many pages does this edition has?"
  answer="Oh, we've got options! Choose from our three plans: 12 pages, 16 pages, or 20 pages. Pick the one that suits your needs best, and we'll make it happen. Easy peasy, right? Pick Here"
/>
<ExpandQuestion
  question="How is this different fro a regular photo album?"
  answer="Forget boring photo albums! imurs magazines are like storytellers crafted with your unique memories and narratives. Instead of just lining up photos, we dive into your stories, write them in an engaging way, and weave them into stunning layouts with chic design. Think of it as a timeless keepsake that breathes life into your special moments!"
/>
<ExpandQuestion
  question="How long will it take to get a Heartstrings Edition?"
  answer="The wait won't be long! Once you share your cherished moments and photos, we'll create a digital draft for your approval (typically within a day). Then, it takes just 2-4 business days for printing and delivery. Total turnaround will be approximately 4-5  days."
/>
<ExpandQuestion
  question="How many images do I need for this edition? "
  answer="We recommend 25-50 photos for perfect layout and design. Don't hesitate to share more though! Our design team will work their magic to curate the best selection and showcase your story flawlessly."
/><ExpandQuestion
  question="What is the process of getting this edition?"
  answer="It's easy! Confirm your order. Fill out our short secret form to share your memories and upload photos. Sit back and relax as our talented design team creates a personalized draft for your approval. If you have any tweaks, let us know! Once you're happy, we'll print and deliver your edition right to your doorstep."
/>
            </div>
       
        </div>
        
      </div>

        </>
       
    )
}

export const ExploreCategory = ()=>{
  const [info, setInfo] = useState(null);
  const [exploreInfo, setExploreInfo] = useState(null);
  const [list,setList] = useState([])
  const [samples,setSamples] = useState([])
  const { link } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product data
        const productResponse = await fetch(`${process.env.REACT_APP_API_URL}api/product`);
        if (!productResponse.ok) {
          throw new Error('Failed to fetch product data');
        }
        const productData = await productResponse.json();
        setInfo(productData.product);
        console.log(productData.product);
  
        // Fetch exploreInfo data
        const exploreResponse = await fetch(`${process.env.REACT_APP_API_URL}api/exploreInfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: ('/'+link)})
        });
  
        if (!exploreResponse.ok) {
          window.location = '/explore/' + link + '/404'; // Assuming '/404' is the route for your 404 page
    return;
        }
  
        const data = await exploreResponse.json();
  
        // Fetch category data
        const categoryResponse = await fetch(`${process.env.REACT_APP_API_URL}api/category`, {
          method: 'POST'
        });
  
        if (!categoryResponse.ok) {
          throw new Error('Failed to fetch category data');
        }
  
        const catdata = await categoryResponse.json();
  
        // Create category dictionary
        const catDict = {};
        catdata.forEach(cat => {
          catDict[cat._id] = cat.name;
        });
  
        // Process exploreInfo data
        const list = [];
        const icnosText = data.whatInside.split('||');
        icnosText.forEach(pair => {
          if (pair.trim().length > 0) {
            list.push({ icon: `/product_icons/${pair.split(',')[0].trim()}.svg`, text: pair.split(',')[0].trim() });
          }
        });
  
        // Process samples
        const givenSamples = (await data.samples.split(',')).map(element => catDict[element.trim()].toLowerCase().replaceAll('-',' ')+' edition');
        console.log(givenSamples);
        // Update state variables
        setSamples(givenSamples);
        setList(list);
        setExploreInfo(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
   
  return(
    exploreInfo?<>
       <BannerV3 url={`https://drive.google.com/thumbnail?authuser=0&sz=w1220&id=${exploreInfo.images['img1']}&cache-control=max-age=172800`} 
       customStyle={{aspectRatio:'1/1'}}
      heading={exploreInfo.tag} 
      sub_heading_2 = {exploreInfo.content} 
      sub_heading = {<span style={{fontFamily:'HK Grotesk-SemiBold',color:'var(--jet-black-2)'}}>Starts at ₹1449</span>} 
      button={exploreInfo.button}
      direction = '/selectPlan?category=65c0da1da8ba8daa251bbc6f&categoryname=Couple&subcategoryname=heartstrings%20x%20valentines'/>

      <IconDiscriptin words = {list}
      heading ={`What will be inside this edition?`} />

      <WhiteMarquee words={exploreInfo.marquee.split(',')}
      style={
          {width:'calc(100% + 40px)',marginLeft:'-20px'}
      }/>
      
      <div className="cardsspacing" >
      {" "}      
          <SimpleHeading text="Explore Couple Editions" customStyle = {{fontFamily:'HK Grotesk-Medium',fontWeight:'500'}}/>
          <ProductSwiper 
          fullscreen={true}
          selectedCats={samples} 
          direction='/selectPlan?category=65c0da1da8ba8daa251bbc6f&categoryname=Couple&subcategoryname=heartstrings%20x%20valentines' 
          info={info}  
          flag={0}/>
    </div>

    <div className="cardsspacing" >
      <SimpleHeading text={"FAQs that might help"}/>
      <div className={"faq-body"} style={{position:'relative',padding:'0'}} >
          <div className='faq-cont-small'>
          <ExpandQuestion
question="How many pages does this edition has?"
answer="Oh, we've got options! Choose from our three plans: 12 pages, 16 pages, or 20 pages. Pick the one that suits your needs best, and we'll make it happen. Easy peasy, right? Pick Here"
/>
<ExpandQuestion
question="How is this different fro a regular photo album?"
answer="Forget boring photo albums! imurs magazines are like storytellers crafted with your unique memories and narratives. Instead of just lining up photos, we dive into your stories, write them in an engaging way, and weave them into stunning layouts with chic design. Think of it as a timeless keepsake that breathes life into your special moments!"
/>
<ExpandQuestion
question="How long will it take to get a Heartstrings Edition?"
answer="The wait won't be long! Once you share your cherished moments and photos, we'll create a digital draft for your approval (typically within a day). Then, it takes just 2-4 business days for printing and delivery. Total turnaround will be approximately 4-5  days."
/>
<ExpandQuestion
question="How many images do I need for this edition? "
answer="We recommend 25-50 photos for perfect layout and design. Don't hesitate to share more though! Our design team will work their magic to curate the best selection and showcase your story flawlessly."
/><ExpandQuestion
question="What is the process of getting this edition?"
answer="It's easy! Confirm your order. Fill out our short secret form to share your memories and upload photos. Sit back and relax as our talented design team creates a personalized draft for your approval. If you have any tweaks, let us know! Once you're happy, we'll print and deliver your edition right to your doorstep."
/>
          </div>
     
      </div>  
    </div>
      </>:<Loadin/>
     
  )
}