import { ButtonBlack } from "../../component/button/button"
import { FeedbackNew } from "../../component/feedback/courosal/feedback1-cards/card1"
import { BasicHeading, SimpleHeading } from "../../component/headings/heading"
import { ProductDashbord } from "../../component/product/cards/productCard/productCard"
import { Productcard } from "../../component/product/product-grid/productCard/productCard"
import { OrderCard } from "../cards/orderCard"
import { ButtonPrimary, ButtonSecondary } from "../dash_buttons/buttons"
import { TagCredit, Tag_1 } from "../headings/headings"
import { Banner, BannerV2 } from "../home/home"
import Profile_Settings from "../profileSetting/form"
import { Dashboard } from "../structure/structure"
import { CardPortfolio } from "./portfolioCard/card"

export const Portfolio = (props)=>{
    
    console.log(props)

    return(
        <Dashboard data={props.data}>
            <div className="body">
            <BasicHeading text="Resonant Portfolio editions"/>
            <BannerV2 url="/banner/portfolio_banner.JPG" heading={'Resonant Portfolio Editions'} sub_heading={`Break free from the digital noise. Our Imurs’ Portfolio Editions spark conversations, ignite empathy, and create emotional bonds between you and your prospects. Let your portfolio whisper "This is who we are, together."`} button={'Create Order'}/>
            <div className="card-container">
      {props.portfolio.map((product) => (
        // <CardPortfolio key={product._id} product={product} />
        <div style={{width:'fit-content'}}>
        <Productcard artwork={product.thumbnail} info={product} category={product.category} title={product.title}/>
        </div>
      ))}
    </div>
            </div>
            
        </Dashboard>
    )
    
} 

export const Suvinor = (props)=>{

  console.log(props.suvenir)
    
    return(
        <Dashboard data={props.data}>
            <div className="body">
            <BasicHeading text="Exquisite Souvenir editions"/>
            <BannerV2 url="/banner/souvenior_banner.JPG" heading={'Exquisite Souvenir Editions'} sub_heading={`More than just films, teasers and images, Imurs’ Souvenir Editions are emotional tapestries woven with your artistry. It’s a gift of everlasting memories, leaving an indelible mark on your clients' hearts.`} button={'Create Order'}/>
            <div className="card-container">
            {props.suvenir.map((product) => (
        // <CardPortfolio key={product._id} product={product} />
        <Productcard artwork={product.thumbnail} info={product} category={product.category} title={product.title} />
      ))}
            </div>
            </div>
            
        </Dashboard>
    )
    
} 