import { useState } from 'react';
import { HedingSubheding } from '../headings/heading';
import { Helmet } from 'react-helmet';

import './explore.css'

import { NavLink } from "react-router-dom";
import { ButtonUnderLine } from '../button/button';
export const ExploreBanner = ({ heading, text, linktext, img,link }) => {
  // Use state to track whether the image has loaded
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <NavLink to={link} className="explore-card">
      <div className="heading">{heading || 'Propose with memories'}</div>

      {/* Display a loading skeleton or a placeholder while the image is loading */}
      {!imageLoaded && <div className="skeleton-placeholder"></div>}

      {/* Actual image */}
      <img
        className={`image ${imageLoaded ? 'visible' : 'hidden'}`}
        alt="Imag"
        src={img || 'explore.png'}
        onLoad={handleImageLoad}
      />

      <p className="explore-text">
        {text ||
          'A proposal magazine covering your introductory encounter, the happy moments you spent together and your future resolutions. How cool is that?'}
      </p>
       <div className="link">
        <div className="text">{linktext || 'Get a proposal magazine'}</div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="none">
        <mask id="mask0_555_2559" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect x="0.24707" y="0.415039" width="14" height="14" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_555_2559)">
        <path d="M8.00096 7.41501L5.31763 4.73167L5.73045 4.31885L8.82661 7.41501L5.73045 10.5112L5.31763 10.0983L8.00096 7.41501Z" fill="#595958"/>
        </g>
      </svg>
      </div> 
      {/* <div className="link"></div> */}
      {/* <ButtonUnderLine arraow={true} text={linktext || 'Get a proposal magazine'} /> */}
    </NavLink>
  );
};

export const ExploreBannerCustom = ({ heading, text, linktext, img,link,isDark,bgcolor,fgColor='var(--isabeline)' }) => {
  // Use state to track whether the image has loaded
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <NavLink to={link} className="explore-card" style={{backgroundColor:bgcolor}}>
      <div className="heading" style={{color:isDark?fgColor:''}}>{heading || 'Propose with memories'}</div>

      {/* Display a loading skeleton or a placeholder while the image is loading */}
      {!imageLoaded && <div className="skeleton-placeholder"></div>}

      {/* Actual image */}
      <img
        className={`image ${imageLoaded ? 'visible' : 'hidden'}`}
        alt="Imag"
        src={img || 'explore.png'}
        onLoad={handleImageLoad}
      />

      <p className="explore-text" style={{color:isDark?fgColor:''}}>
        {text ||
          'A proposal magazine covering your introductory encounter, the happy moments you spent together and your future resolutions. How cool is that?'}
      </p>
       <div className="link">
        <div className="text" style={{color:isDark?fgColor:''}}>{linktext || 'Get a proposal magazine'}</div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="none">
        <mask id="mask0_555_2559" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect x="0.24707" y="0.415039" width="14" height="14" fill={isDark?fgColor:"#D9D9D9"}/>
        </mask>
        <g mask="url(#mask0_555_2559)">
        <path d="M8.00096 7.41501L5.31763 4.73167L5.73045 4.31885L8.82661 7.41501L5.73045 10.5112L5.31763 10.0983L8.00096 7.41501Z" fill={isDark?fgColor:"#595958"}/>
        </g>
      </svg>
      </div> 
      {/* <div className="link"></div> */}
      {/* <ButtonUnderLine arraow={true} text={linktext || 'Get a proposal magazine'} /> */}
    </NavLink>
  );
};

export const ExploreWindow = ()=>{
    return(
        
        <div className='body' style={{padding:'20px',paddingBottom:'100px'}}>
          <Helmet>
            <title>I Am Your Story - Explore / Samples</title>
          </Helmet>
          <HedingSubheding heading="Weaving memories, just for you" sub_heading = "A glimpse of how you can turn your memories into living stories" />
            <div className='explore-card-cont'>
            
            {/* <ExploreBannerCustom
            bgcolor={'#F7E0E0'} 
            fgColor='#882A2B'
            isDark={true}
            img="banner/valentines_banner.png"
            heading="Surprise your Valentine"
            text="This edition is a testament to your love story, a reminder of the laughter, the tears, and the unwavering connection that binds you. Dive into heartfelt narratives penned just for you and rediscover the magic that brought you together."
            linktext="Heartstrings x Valentines Edition"
            link="/valentines"
            /> */}

            <ExploreBanner 
            img="explore_banners/Anniversary.png"
            heading="Spice Up Your Date Night"
            text="Treat your special someone to a date night experience unlike any other with an imurs edition filled with personalized poems, songs, love letters and memories of those little intimate moments."
            linktext="Love's Little Treasures Edition"
            link="/selectPlan?category=651c40496212f1824e4ce4be&categoryname=Couple&subcategoryname=stolen%20glances%20%26%20heartbeats"
            />

            <ExploreBanner 
            img="explore_banners/Birthday.png"
            heading="Unwrap a Smile, Not Just a Gift"
            text="Forget the generic store-bought stuff! You can now personalize an imurs edition with memories that's unique, meaningful, and guaranteed to put a smile on their face."
            linktext="Birthday Spotlight Edition"
            link="/selectPlan?category=6590402d60c1efcf9794a318&categoryname=Gifting&subcategoryname=birthday%20spotlight"
            />

            <ExploreBanner 
            img="explore_banners/Graduation.png"
            heading="Your Unofficial College Degree"
            text="Yes, you earned your degree but college wasn't just about textbooks and lectures, it was about stolen moments, shared secrets, and memories that will last a lifetime. This edition is an unprofessional degree to that."
            linktext="High Fives and Diplomas Edition"
            link="/selectPlan?category=651c1a5031d23ba73f1b88c6&categoryname=Self&subcategoryname=high%20fives%20%26%20diplomas"
            />

            <ExploreBanner 
            img="explore_banners/Wedding.jpg"
            heading="Hold Your Wedding Day Close"
            text="Beyond the dress and the guests, this edition holds the whispers of forever. Relive the tender moments, the tearful vows, and the love that shines brighter than any diamond."
            linktext="Wedding Tears and Tiaras Edition"
            link="/selectPlan?category=6529948df4750b49143ab145&categoryname=Couple&subcategoryname=wedding%20tears%20%26%20tiaras"
            />

            <ExploreBanner 
            img="explore_banners/Praposal.jpg"
            heading="Pop the Question Uniquely"
            text={`Pop the question in a way that says forever. Craft an imurs proposal edition overflowing with love, memories, and most importantly - explaining “why us" before asking the question. `}
            linktext=" Every Reason and One Proposal Edition"
            link="/selectPlan?category=65903e4460c1efcf9794a308&categoryname=Couple&subcategoryname=every%20reason%20%26%20one%20proposal"
            />

            <ExploreBanner 
            img="explore_banners/Family.png"
            heading="Travel Memories on Your Shelf"
            text="More than souvenirs on shelves, this edition paints a vibrant tapestry of friendship, family, breathtaking sights, and memories that warm your heart every time you glance its way."
            linktext="Trip Memoir Edition"
            link="/selectPlan?category=6551fd822c7193abf610920d&categoryname=Travel&subcategoryname=trip%20memoir"
            />

            </div>
        </div>
    )
}

export const FeatureWindow = ()=>{
  return(
    <div className='feature-cont'>
      <FeatureCard img="/fetures_icons/time.svg" heading="Within 48 hours" sub_heading="Swift Personalization in just 48 hours"/>
      
      <FeatureCard img="/fetures_icons/revisions.svg" heading="2 Free Revisions" sub_heading="Perfecting memories, your way, hassle-free"/>

      <FeatureCard img="/fetures_icons/pencil.svg" heading="Storytelling" sub_heading="Narratives etched in personalized elegance"/>
      
      <FeatureCard img="/fetures_icons/pencildot.svg" heading="Chic Layouts" sub_heading="Weaving memories into visual poetry"/>
      
      <FeatureCard img="/fetures_icons/caption.svg" heading="Classic Typefaces" sub_heading="Fonts evoking timeless reminiscence"/>
      
      <FeatureCard img="/fetures_icons/encrypt.svg" heading="Secured Details" sub_heading="Safely stored and transferred"/>
    </div>
  )
}

export const FeatureCard = ({img,heading,sub_heading})=>{
  return(
    <div className="desktop-features">
      <img className="icon" alt="Icon" src={img} />
      <div className="heading">{heading}</div>
      <div className="sub-heading">{sub_heading}</div>
    </div>
  )
}