import "./threeanimation.css";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useInView } from "framer-motion";
import { NavLink } from "react-router-dom";
import { SuccessV2 } from "../login/success";

const numRows = 50;
const numCols = 100;

export const BackGround = React.memo(({ blur }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed
      const videoSource = isMobile ? 'vid_mob.mp4' : 'vid_desk.mp4';

      if (videoRef.current) {
        videoRef.current.src = videoSource;
        videoRef.current.load();
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      video.play().catch((error) => {
        console.error("Video playback error:", error);
      });
    };

    // Use the 'canplay' event to trigger video playback when it's ready
    video.addEventListener("canplay", playVideo);

    // Clean up event listener on unmount
    return () => {
      video.removeEventListener("canplay", playVideo);
    };
  }, []);

  const renderGrid = () => {
    const grid = [];
    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        if (i % 2 === 0) {
          row.push(
            <div key={`${i}-${j}`} className="rhombus">
              <img
                src={`/backgroundSvg/${(j % 8) + 1}.svg`}
                alt={`background-${j}`}
              />
            </div>
          );
        } else {
          if (j % 2 === 0) {
            row.push(
              <div key={`${i}-${j}`} className="rhombus">
                <img src={`/backgroundSvg/Logo.svg`} alt={`background-${j}`} />
              </div>
            );
          }
        }
      }
      grid.push(
        <div key={i} className="flex-row">
          {row}
        </div>
      );
    }
    return grid;
  };


  return <div className="flex-container">{renderGrid()}</div>;
});

function smoothScroll(target, duration, offset) {
  const targetElement = document.querySelector(target);
  const targetPosition = targetElement.offsetTop - offset;
  const startPosition = window.scrollY || window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const ease = easeInOut(progress);
    window.scrollTo(0, startPosition + distance * ease);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  function easeInOut(t) {
    return 0.5 - 0.5 * Math.cos(Math.PI * t);
  }

  requestAnimationFrame(animation);
}


export const ScrollAnimation = React.memo(({ int, valentinesNotify }) => {
  const canvasRef = useRef(null);
  const [frames, setFrames] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const [images, setImages] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  const createButtonsRef = useRef(null);
  const goDownButtonRef = useRef(null);

  const createButtonsTar = useRef(null);
  const goDownButtonTar = useRef(null);

  const container = useRef(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const videoRef = useRef(null);

  const [videoLoading,setVideoLoading] = useState(true)
  const [framesLoading,setFramesLoading] = useState(true)

  const BATCH_SIZE = 10; // Set the batch size as per your requirement
  const [currentBatch, setCurrentBatch] = useState(0);

  useEffect(() => {
    const generateFrames = async () => {
      try {
        const generatedFrames = await generateFileNames(int);
        setFrames(generatedFrames);
      } catch (error) {
        console.error("Error generating frames:", error);
      }
    };

    generateFrames();
  }, [int]);

  useEffect(() => {
    const handleScroll = () => {
      const speedMultiplier = 2;
      const scrollElement = document.querySelector(".landingMain");
      const scrollPosition =
        ((scrollElement.scrollTop - 90) * speedMultiplier) /
        (scrollElement.scrollHeight - scrollElement.clientHeight);
      setScrolled(scrollElement.scrollTop);
      if (
        scrollElement.scrollTop > 1000 &&
        scrollElement.scrollTop <
          document.querySelector(".body").offsetTop - 700
      ) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
      //console.log(scrollPosition);
      setScrollProgress(scrollPosition);


      const goDownButton = goDownButtonRef.current;
      const goDownbutton = goDownButtonTar.current;

      // Check if buttons are not null
      if (goDownButton && goDownbutton) {
        const goDownButtonRect = goDownButton.getBoundingClientRect();

        // Update the position of the fixed link based on the buttons
        goDownbutton.style.left = `${goDownButtonRect.left}px`;
        goDownbutton.style.top = `${goDownButtonRect.top}px`;
        goDownbutton.style.width = `${goDownButtonRect.width}px`;
        goDownbutton.style.height = `${goDownButtonRect.height}px`;
      }

      const createButtons = createButtonsRef.current;
      const createbuttons = createButtonsTar.current;

      // Check if buttons are not null
      if (createButtons && createbuttons) {
        const createButtonsRect = createButtons.getBoundingClientRect();

        // Update the position of the fixed link based on the buttons
        createbuttons.style.left = `${createButtonsRect.left}px`;
        createbuttons.style.top = `${createButtonsRect.top}px`;
        createbuttons.style.width = `${createButtonsRect.width}px`;
        createbuttons.style.height = `${createButtonsRect.height}px`;
      }

      const sideNav = document.querySelector('.side-navigation')
      if(sideNav && scrollElement.scrollTop < 3880 && scrollElement.scrollTop > 5){
            sideNav.style.opacity = '0'
      }else{
        sideNav.style.opacity='1'
      }

      if(sideNav && scrollElement.scrollTop < 4000){
        document.querySelector('#faq-button').style.transform = "scale(0)";
      }else{
        document.querySelector('#faq-button').style.transform = "scale(1)";
        valentinesNotify()
      }


    };

    const landingMainElement = document.querySelector(".landingMain");
    landingMainElement.addEventListener("scroll", handleScroll);

    return () => {
      landingMainElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const start = currentBatch * BATCH_SIZE;
        const end = Math.min(start + BATCH_SIZE, frames.length);

        const imagePromises = frames.slice(start, end).map((frameName) => {
          const framePath = `/Frames/${frameName}`;
          return loadImage(framePath);
        });

        const loadedImages = await Promise.all(imagePromises);
        setImages((prevImages) => [...prevImages, ...loadedImages]);
        setFramesLoading(false)
        // Move to the next batch
        setCurrentBatch((prevBatch) => prevBatch + 1);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    // Load images only if there are more batches to load
    if (currentBatch * BATCH_SIZE < frames.length) {
      loadImages();
    }
  }, [frames, currentBatch]);

  useEffect(() => {
    try {
      if (images.length > 0) {
        const frameIndex = Math.floor(scrollProgress * (images.length - 1));
        if (frameIndex > int - 15) {
          return;
        }

        const img = images[frameIndex];

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        context.imageSmoothingEnabled = true;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    } catch (e) {
      console.log(e);
    }
  }, [scrollProgress, images, int]);
  
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed
      const videoSource = isMobile ? 'vid_mob.mp4' : 'vid_desk.mp4';

      if (videoRef.current) {
        videoRef.current.src = videoSource;
        videoRef.current.load();
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      video.play().catch((error) => {
        console.error("Video playback error:", error);
      });
      video.playbackRate = 0.7;
    };

    // Use the 'canplay' event to trigger video playback when it's ready
    video.addEventListener("canplay", playVideo);

    // Clean up event listener on unmount
    return () => {
      video.removeEventListener("canplay", playVideo);
    };

    

  }, []);

  

  useEffect(() => {
    // Set a timeout to check the status after 7 seconds
    const timeoutId = setTimeout(() => {
      // Check the status and perform actions accordingly
      if(videoLoading || framesLoading){
        console.log('got special case...');
        setFramesLoading(false)
        setVideoLoading(false)
        document
                  .querySelector(".landingMain")
                  .scrollTo({
                    behavior: "smooth",
                    top: document.querySelector(".body").offsetTop,
                  });
      }
      if (videoLoading) {
        // Take some actions when status is true
        console.log('Video Status is true after 7 seconds. Performing actions...');
        // Perform your actions here
      } else {
        // Status is false after 7 seconds
        console.log('Video Status is false after 7 seconds.');
      }
      if (framesLoading) {
        // Take some actions when status is true
        console.log('frame Status is true after 7 seconds. Performing actions...');
        // Perform your actions here
      } else {
        // Status is false after 7 seconds
        console.log('frame Status is false after 7 seconds.');
      }
    }, 7000); // 7000 milliseconds = 7 seconds

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, [videoLoading,framesLoading]);

  function pageScroll(element, pixels, speed) {
    let currentScrollPosition = 0;
    const scrollInterval = setInterval(function() {
        if (currentScrollPosition < pixels) {
            element.scrollBy(0, 1);
            currentScrollPosition++;
        } else {
            clearInterval(scrollInterval);
        }
    }, speed);
}


  return (
    <>
      {(videoLoading || framesLoading) && <SuccessV2/>}

      <div
        className="preserving-mem"
        style={{
          position: "absolute",
          top: "300px",
          margin:'auto',
          width:'100%',
          opacity: `${scrolled > 200 ? "0" : "1"}`,
        }}
      >
        <p className="text">Introducing a modern way of <br></br>preserving memories </p>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
      </div>
      <div className="canvas-cont" ref={container}>
        <div
      className="final-vid-bg"
      style={{ filter: `blur(${scrolled > 180 ? "10px" : "0px"})` }}
    >
      <video autoPlay muted loop playsInline preload="auto" ref={videoRef} onLoadedData={()=>{console.log('video');setVideoLoading(false)}}>
        <source src="vid_mob.mp4" type="video/mp4" />
      </video>
    </div>
        <canvas
          width={1000}
          height={1000}
          ref={canvasRef}
          style={{
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: `${scrolled > 200 ? "1" : "0"}`,
          }}
        />
        <div
          className="preserving-mem"
          style={{
            opacity: `${scrolled > 1000 ? "1" : "0"}`,
            pointerEvents: `${scrolled > 1000 ? "all" : "none"}`,
          }}
        >
          <p className="text">Preserving memories into timeless art.</p>
          <div className="but-cont">
            <div
              className="but-outine"
              ref={goDownButtonRef}
              onClick={() => {
                document
                  .querySelector(".landingMain")
                  .scrollTo({
                    behavior: "smooth",
                    top: document.querySelector(".body").offsetTop - 100,
                  });
              }}
            >
              Dive into our Culture
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 6"
                fill="none"
              >
                <path
                  d="M4.00006 4.39434L7.45006 0.944336L7.98083 1.47511L4.00006 5.45589L0.0192847 1.47511L0.55006 0.944336L4.00006 4.39434Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="but-solid" ref={createButtonsRef}>
              <NavLink to="/plans" style={{ color: "inherit" }}>
                Create Order
              </NavLink>
            </div>
          </div>
        </div>
        
      </div>
      {scrolled > 1000 && scrolled < 4200?<>
        <div
  ref={goDownButtonTar}
  style={{ position: 'fixed', zIndex: '10' }}
  onClick={() => {
    const landingMain = document.querySelector('.landingMain');
    const bodyElement = document.querySelector('.body');
    const bodyOffsetTop = bodyElement.offsetTop;

    // Calculate the distance to scroll
    const distanceToScroll = bodyOffsetTop - landingMain.scrollTop;

    // Set the duration for the scroll animation (in milliseconds)
    const duration = landingMain.scrollTop>2000? 3000:4500; // 3 seconds

    // Calculate the number of frames based on 60 frames per second
    const numberOfFrames = duration / (1000 / 60);

    // Calculate the distance to scroll per frame
    const distancePerFrame = distanceToScroll / numberOfFrames;

    // Initial timestamp
    let startTime = null;

    // Define the scroll function
    function scrollStep(timestamp) {
      if (!startTime) {
        startTime = timestamp;
      }

      // Calculate the elapsed time
      const elapsedTime = timestamp - startTime;

      // Calculate the next scroll position based on elapsed time
      const nextScrollPosition = landingMain.scrollTop + distancePerFrame;

      // Update the scroll position, but ensure it does not go beyond the target position
      landingMain.scrollTo(0, Math.min(nextScrollPosition, bodyOffsetTop));

      // Check if the duration has been reached
      if (elapsedTime < duration && nextScrollPosition < bodyOffsetTop) {
        // Continue scrolling
        window.requestAnimationFrame(scrollStep);
      }
    }

    // Start the scrolling animation after a short delay
    setTimeout(() => {
      window.requestAnimationFrame(scrollStep);
    }, 50); // Delay of 50 milliseconds before starting the animation
  }}
></div>



        <NavLink to='/plans'  ref={createButtonsTar} style={{position:'fixed',zIndex:'10'}}></NavLink>
        </>:null}
    </>
    );
  });

async function generateFileNames(totalFiles) {
  const fileNames = [];

  for (let i = 12; i <= totalFiles; i++) {
    fileNames.push(`Comp_${String(i).padStart(5, "0")}.png`);
  }

  return fileNames;
}

async function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}

/*import "./threeanimation.css";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useInView } from "framer-motion";
import { NavLink } from "react-router-dom";

const numRows = 50;
const numCols = 100;

export const BackGround = React.memo(({ blur }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      video.play().catch((error) => {
        console.error("Video playback error:", error);
      });
    };

    // Use the 'canplay' event to trigger video playback when it's ready
    video.addEventListener("canplay", playVideo);

    // Clean up event listener on unmount
    return () => {
      video.removeEventListener("canplay", playVideo);
    };
  }, []);

  const renderGrid = () => {
    const grid = [];
    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        if (i % 2 === 0) {
          row.push(
            <div key={`${i}-${j}`} className="rhombus">
              <img
                src={`/backgroundSvg/${(j % 8) + 1}.svg`}
                alt={`background-${j}`}
              />
            </div>
          );
        } else {
          if (j % 2 === 0) {
            row.push(
              <div key={`${i}-${j}`} className="rhombus">
                <img src={`/backgroundSvg/Logo.svg`} alt={`background-${j}`} />
              </div>
            );
          }
        }
      }
      grid.push(
        <div key={i} className="flex-row">
          {row}
        </div>
      );
    }
    return grid;
  };

  return (
    <div
      className="final-vid-bg"
      style={{ filter: `blur(${blur ? "10px" : "0px"})` }}
    >
      <video autoPlay muted loop playsInline ref={videoRef}>
        <source src="vid_mob.mp4" type="video/mp4" />
      </video>
    </div>
  );

  return <div className="flex-container">{renderGrid()}</div>;
});

export const ScrollAnimation = React.memo(({ int }) => {
  const canvasRef = useRef(null);
  const [frames, setFrames] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const [images, setImages] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const generateFrames = async () => {
      try {
        const generatedFrames = await generateFileNames(int);
        setFrames(generatedFrames);
      } catch (error) {
        console.error("Error generating frames:", error);
      }
    };

    generateFrames();
  }, [int]);

  useEffect(() => {
    const handleScroll = () => {
      const speedMultiplier = 2;
      const scrollElement = document.querySelector(".landingMain");
      const scrollPosition =
        ((scrollElement.scrollTop - 90) * speedMultiplier) /
        (scrollElement.scrollHeight - scrollElement.clientHeight);
      setScrolled(scrollElement.scrollTop);
      if (
        scrollElement.scrollTop > 1000 &&
        scrollElement.scrollTop <
          document.querySelector(".body").offsetTop - 700
      ) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
      //console.log(scrollPosition);
      setScrollProgress(scrollPosition);
    };

    const landingMainElement = document.querySelector(".landingMain");
    landingMainElement.addEventListener("scroll", handleScroll);

    return () => {
      landingMainElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const BATCH_SIZE = 23; // Set the batch size as per your requirement
  const [currentBatch, setCurrentBatch] = useState(0);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const start = currentBatch * BATCH_SIZE;
        const end = Math.min(start + BATCH_SIZE, frames.length);

        const imagePromises = frames.slice(start, end).map((frameName) => {
          const framePath = `/Frames/${frameName}`;
          return loadImage(framePath);
        });

        const loadedImages = await Promise.all(imagePromises);
        setImages((prevImages) => [...prevImages, ...loadedImages]);

        // Move to the next batch
        setCurrentBatch((prevBatch) => prevBatch + 1);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    // Load images only if there are more batches to load
    if (currentBatch * BATCH_SIZE < frames.length) {
      loadImages();
    }
  }, [frames, currentBatch]);

  useEffect(() => {
    try {
      if (images.length > 0) {
        const frameIndex = Math.floor(scrollProgress * (images.length - 1));
        if (frameIndex > int - 15) {
          return;
        }

        const img = images[frameIndex];

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        context.imageSmoothingEnabled = true;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    } catch (e) {
      console.log(e);
    }
  }, [scrollProgress, images, int]);

  const container = useRef(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const createButtonsRef = useRef(null);
  const goDownButtonRef = useRef(null);

  const createButtonsTar = useRef(null);
  const goDownButtonTar = useRef(null);



  return (
    <>
      <div
        className="preserving-mem"
        style={{
          position: "absolute",
          top: "300px",
          opacity: `${scrolled > 200 ? "0" : "1"}`,
        }}
      >
        <p className="text">Introducing a modern way of preserving memories </p>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
        <div
          className="scroll-down"
          style={{ position: "relative" }}
          //  onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
        ></div>
      </div>
      <div className="canvas-cont" ref={container}>
        <BackGround blur={scrolled > 350 ? true : false} />
        {/* <div 
      className="heading"
       ref={ref}
       style={{
        transform: isInView ? "none" : "translateX(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
      }}
       >
      <i>Beyond</i><br/>
      Imagination 
      </div> /}
        <canvas
          width={1000}
          height={1000}
          ref={canvasRef}
          style={{
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: `${scrolled > 200 ? "1" : "0"}`,
          }}
        />
        <div
          className="preserving-mem"
          style={{
            opacity: `${scrolled > 1000 ? "1" : "0"}`,
            pointerEvents: `${scrolled > 1000 ? "all" : "none"}`,
          }}
        >
          <p className="text">Preserving memories into timeless art.</p>
          <div className="but-cont">
            <div
              className="but-outine"
              ref={goDownButtonRef}
              onClick={() => {
                document
                  .querySelector(".landingMain")
                  .scrollTo({
                    behavior: "smooth",
                    top: document.querySelector(".body").offsetTop - 100,
                  });
              }}
            >
              Dive into our Culture
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 6"
                fill="none"
              >
                <path
                  d="M4.00006 4.39434L7.45006 0.944336L7.98083 1.47511L4.00006 5.45589L0.0192847 1.47511L0.55006 0.944336L4.00006 4.39434Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="but-solid" ref={createButtonsRef}>
              <NavLink to="/plans" style={{ color: "inherit" }}>
                Create Order
              </NavLink>
            </div>
          </div>
        </div>
        <div ref={goDownButtonTar}></div>
        <div ref={createButtonsTar}></div>
      </div>
      {/* <div className="scroll-down"
       style={showScroll?{opacity:'1',marginBottom:'90px'}:{opacity:'0',marginBottom:'-90px',}}
       onClick={()=>{document.querySelector('.landingMain').scrollTo({behavior: "smooth",top:(document.querySelector('.body').offsetTop-100)})}}
      ></div> /}
    </>
  );
});

async function generateFileNames(totalFiles) {
  const fileNames = [];

  for (let i = 12; i <= totalFiles; i++) {
    fileNames.push(`Comp 2_${String(i).padStart(5, "0")}.png`);
  }

  return fileNames;
}

async function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}
*/