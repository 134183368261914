import React, { useEffect } from 'react';
import './success.css'
import { HedingSubheding, MainHeading } from '../headings/heading';
import { FallingLines, Oval, TailSpin, ThreeDots } from 'react-loader-spinner';
export default function Success() {
  useEffect(() => {
    // Check if the page has been reloaded before
    const hasReloaded = localStorage.getItem('hasReloaded');

    // If it hasn't been reloaded, perform the reload and set the flag
    if (hasReloaded == 'false') {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <div className='body' style={{justifyContent:'center',alignItems:'center',position:'fixed',height:'100%',width:'100%',margin:'0',zIndex:'999999999999',top:'0',background:'var(--isabeline)'}}>
        
<TailSpin
  height="80"
  width="80"
  color="#717170"
  strokeWidth="1"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{
    backgroundImage: 'url(/logos/new_loding.svg)',
    backgroundSize:'50%',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    stroke:'1'
  }}
  wrapperClass=""
  visible={true}
/>
    </div>
  );
}

export function SuccessV2() {
  useEffect(() => {
    // Check if the page has been reloaded before
    const hasReloaded = localStorage.getItem('hasReloaded');

    // If it hasn't been reloaded, perform the reload and set the flag
    if (hasReloaded == 'false') {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <div className='body' style={{justifyContent:'center',alignItems:'center',position:'fixed',height:'100%',width:'100%',margin:'0',zIndex:'999999999999',top:'0',background:'var(--isabeline)'}}>
        
<TailSpin
  height="80"
  width="80"
  color="#717170"
  strokeWidth="1"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{
    backgroundImage: 'url(/logos/new_loding.svg)',
    backgroundSize:'50%',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    stroke:'1'
  }}
  wrapperClass=""
  visible={true}
/>
    </div>
  );
}

export const Loadin = ()=>{

  return (
    <div className='body' style={{justifyContent:'center',alignItems:'center',marginTop:'200px',paddingBottom:'350px'}}>
      
      <TailSpin
  height="20"
  width="20"
  color="var(--jet-black)"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{
   
  }}
  wrapperClass=""
  visible={true}
/>

    </div>
  );
}
