import PropTypes from "prop-types";
import React from "react";
import "./card1.css";
import { NavLink } from "react-router-dom";
import { ButtonUnderLine } from "../../../button/button";

export const FeedbackNew = ({name,text,city,img,desk}) => {
    return (
        <div className={`feedback-new ${desk?'feed-desk':''}`}>
            <img className="rectangle" alt="Rectangle" src={`${img}`}/>
            {desk?(
                <div className="feed-desk-cont">
                <div className="frame-2">
                    <div className="text-wrapper">{name}</div>
            </div>
            <p className="element-prestalo-onli">
                    {text}
            </p>
            <NavLink exact to="/plans"><ButtonUnderLine text={city}/></NavLink>
                </div>
            ):(
                <>
                <div className="frame-2">
                    <div className="text-wrapper">{name}</div>
            </div>
            <p className="element-prestalo-onli">
                    {text}
            </p>
            <NavLink exact to="/plans"><ButtonUnderLine text={city}/></NavLink>
                </>
            )}
            
        </div>
    );
};

FeedbackNew.propTypes = {
    rectangle: PropTypes.string, 
};
