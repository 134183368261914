import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
import Price from './component/price/price';
import Plan from './component/plans/plan';
import { Home } from './component/pricing/home';
import {Product} from './component/product/product';
import { NavbarLanding } from './component/navbarLanding/navbar';
import { FooterLanding, PropertyFooterWrapper } from './component/footer/footer';
import { Otp } from './component/login/login';
import Form from './component/form/form';
import { DashboardHome } from './dashboard/home/home';
import { Dashboard, TopNavigation } from './dashboard/structure/structure';
import Success, { Loadin } from './component/login/success';
import Profile_Settings from './dashboard/profileSetting/form';
import { AboutUs, Cancillation, ContactUS, Privacy, TandC } from './component/legal/cancillation';
import { DashboardPricing } from './dashboard/dashboardPricing/dashboardPricing';
import { OrdersB2B, OrdersD2C } from './dashboard/orders/orderpage';
import { Portfolio, Suvinor } from './dashboard/portfolio/portfolio';
import { useLocation } from 'react-router-dom';
import Productdash from './component/product/productdash';
import { OrderSample, Orderd2csample } from './dashboard/new-form/forms/order-sample';
import CreateOrder from './dashboard/new-form/forms/order-form';
import CheckOut from './dashboard/checkout/checkout';
import { TailSpin } from 'react-loader-spinner';
import { LandingDash } from './component/landingDash/landingDash';
import { CategoryWindow, D2COrdersummry, PriceD2C, PriceD2CCategory, SubCategoryWindow } from './component/pricingD2c/priced2c';
import { OtpD2C } from './component/login/loginD2C';
import Profile_SettingsD2C from './component/profileSettingD2C/form';
import { ExploreWindow } from './component/exploremore/explore';
import CheckOutD2C, { ErrorComponent } from './component/checkoutD2C/checkout';
import Community from './component/test1/test1';
import Ordercheck from './component/test/test';
import { B2BOrdersummry } from './dashboard/checkout/orderSummryB2B';
import { PreviewWindow } from './component/preview/preview';
import AudioQuestion from './component/abstract/abstract';
import { Tracking } from './component/tracking/tracking';
import { BackGround, ScrollAnimation } from './component/3dAnime/threeanimation';
import PreviewWindowQR from './component/preview/previewqr';
import { NotFounf } from './component/marquee/marquee';
import { ExploreCategory, Valentine } from './component/valentines/valentine';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollElement = document.querySelector('.landingMain');
    if (scrollElement) {
      scrollElement.scrollTop = 0;
      scrollElement.style.transition = 'none'
      scrollElement.style.opacity = 0;

      setTimeout(() => {
      scrollElement.style.transition = '0.3s'

      scrollElement.style.opacity = 1;
        
      }, 5);
    }
    const scrollElement2 = document.querySelector('.main');
    if (scrollElement2) {
      scrollElement2.scrollTop = 0;
      scrollElement2.style.transition = 'none'
      scrollElement2.style.opacity = 0;

      setTimeout(() => {
      scrollElement2.style.transition = '0.3s'

      scrollElement2.style.opacity = 1;
        
      }, 5);
    }
    const sideNav = document.querySelector('.side-navigation')
    if(sideNav){
      sideNav.style.opacity='1'
    }
  }, [pathname]);

  return null;
}

export const INS = (numberString) =>{
  if(numberString === "-"){
    return "-"
  }
  const formattedNumber = new Intl.NumberFormat("en-IN").format(numberString)

  return formattedNumber;
}

function CheckRelode(){
  if (localStorage.getItem('refreshPage') == 'true' && window.location.pathname !== '/processPayment') {
    localStorage.setItem('refreshPage', 'false');
    window.location.reload();
  }
}

function LandingRoutes({data={}}) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Simulate fetching your product data from an API or other source
    const fetchData = async () => {
      // Replace this with your actual data-fetching logic
      const response = await fetch(process.env.REACT_APP_API_URL + 'api/product'); // Example API endpoint
      const data = await response.json();
      setProducts(data.product);
     
    };

    fetchData();
  }, []);

  //console.log(data)
  return (
    <Router>
      <LandingDash>
      <ScrollToTop/>

        <NavbarLanding />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/register" element={<Form />} />
            <Route path="/pricing" element={<Plan value={''} />} />
            <Route path="/plans" element={<PriceD2C/>} />
            <Route path="/explore" element={<ExploreWindow/>} />
            <Route path='/orderD2C' element={<PriceD2C/>}/>
            <Route path='/selectPlan' element={<PriceD2CCategory/>}/>
            <Route path='/orders' element={data.phone?<OrdersD2C/>:<Ordercheck/>}/>
            <Route path='/samples' element={<Orderd2csample data={products}/>} />
            <Route path='/category' element={data.phone?<CategoryWindow/>:<OtpD2C/>}/>
            <Route path='/sub_category' element={data.phone?<SubCategoryWindow/>:<OtpD2C/>}/>
            <Route path='/createOrder' element={data.phone?null:<Otp />} />
            <Route path="/account" element={data.phone?<Profile_SettingsD2C data={data}/>:<Community/>} />
            <Route path="/Partnerlogin" element={<Otp />} />
            <Route path='/summry' element={<Otp />} />
            <Route path="/login" element={<OtpD2C />} />
            <Route path="/dashboard" element={<Success/>}></Route>
            <Route path='/registration' element={<Form/>} />
            <Route path='/privacy' element={<Privacy/>} />
            <Route path='/about' element={<AboutUs/>} />
            <Route path='/refundpolicy' element={<Cancillation/>} />
            <Route path='/termsandconditions' element={<TandC/>} />
            <Route path='/contactus' element={<ContactUS/>} />
            <Route path='/xxyyzz' element={<Loadin/>} />
            <Route path='/abstract/:edition' element={<div className='body' style={{minHeight:'100%'}}><AudioQuestion/></div>} />
            <Route path='/processPayment' element={<CheckOutD2C data={data}/>} />
            <Route path="/edition/:edition" element={<div className='body' style={{ padding: '20px', minHeight: '100%',marginTop:'80px' }}><PreviewWindow/></div>}/>
            <Route path='/tracking/:edition' element={<div className='body' style={{padding:'20px',minHeight:'100%'}}><Tracking data={data}/></div>} />
            <Route path='/checkout' element={<D2COrdersummry customer={data}/>}/>
            <Route path='/EAAUbfCeJm1/:edition' element={<div className='body' style={{ padding: '20px', minHeight: '100%',marginTop:'80px' }}><PreviewWindowQR/></div>}/>
            <Route path='/valentines' element={<div className='body' style={{ padding: '20px', minHeight: '100%',marginTop:'60px' }}><Valentine/></div>}/>
            <Route path="/explore/:link" element={<div className='body' style={{ padding: '20px', minHeight: '100%',marginTop:'60px' }}><ExploreCategory/></div>} />
            <Route path="*" element={<div className='body' style={{minHeight:'100%'}}><NotFounf/></div>} />
          </Routes>
        <FooterLanding />
      </LandingDash>
      
    </Router>
  );
}

function DashboardRoutes(props) {
  const [products, setProducts] = useState([]);
  const [suvenirProducts, setSuvenirProducts] = useState([]);
  const [portfolioProducts, setPortfolioProducts] = useState([]);
  useEffect(() => {
    // Simulate fetching your product data from an API or other source
    const fetchData = async () => {
      // Replace this with your actual data-fetching logic
      const response = await fetch(process.env.REACT_APP_API_URL + 'api/product'); // Example API endpoint
      const data = await response.json();
      setProducts(data.product);
      //console.log(data.product)
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const suvenirList = products.filter(product => product.display.suvenir);
      const portfolioList = products.filter(product => product.display.portfolio);

      setSuvenirProducts(suvenirList);
      setPortfolioProducts(portfolioList);
     
    }
  }, [products]);

  //console.log(suvenirProducts)
  //console.log(portfolioProducts)

  
  


  return (
    <Router>
      <CheckRelode/>
      <ScrollToTop/>
        <Routes>
          <Route path="/dashboard" element={<DashboardHome data={props.data} />} />
          <Route path="/" element={<DashboardHome data={props.data} />} />
          <Route path="/plans" element={<CreateOrder data={props.data}/>} />
          <Route path="/pricing" element={<DashboardPricing data={props.data}/>} />
          <Route path="/edition/:edition" element={<Dashboard data={props.data}><PreviewWindow/></Dashboard>}/>
          <Route path='/EAAUbfCeJm1/:edition' element={<Dashboard data={props.data}><PreviewWindowQR/></Dashboard>}/>
          <Route path="/orders" element={<OrdersB2B data={props.data}/>} />
          <Route path="/portfolio" element={<Portfolio data={props.data} portfolio={portfolioProducts}/> } />
          <Route path="/souvenir" element={<Suvinor data={props.data} suvenir={suvenirProducts}/>} />
          <Route path='/profile' element={<Profile_Settings data={props.data}/>} />
          <Route path='/product' element={<Productdash data={props.data}/>}/>
          <Route path='/sample' element={<OrderSample data={props.data}/>} />
          <Route path='/valentines' element={<Valentine data={props.data}/>} />
          <Route path='/createOrder' element={<CreateOrder data={props.data}/>} />
          <Route path='/processPayment' element={<CheckOut data={props.data}/>} />
          <Route path='/about' element={<Dashboard data={props.data}><AboutUs/></Dashboard>} />
          <Route path='/privacy' element={<Dashboard data={props.data}><Privacy/></Dashboard>} />
          <Route path='/refundpolicy' element={<Dashboard data={props.data}><Cancillation/></Dashboard>} />
          <Route path='/termsandconditions' element={<Dashboard data={props.data}><TandC/></Dashboard>} />
          <Route path='/contactus' element={<Dashboard data={props.data}><ContactUS/></Dashboard>} />
          <Route path='/xxyyzz' element={<B2BOrdersummry/>} />
          <Route path='/summry' element={<Dashboard data={props.data}><B2BOrdersummry/></Dashboard>} />
          <Route path='/abstract/:edition' element={<Dashboard data={props.data}><AudioQuestion/></Dashboard>} />
          <Route path='/tracking/:edition' element={<Dashboard data={props.data}><Tracking data={props.data}/></Dashboard>}/>
          <Route path="*" element={<Dashboard data={props.data}><NotFounf/></Dashboard>} />

        </Routes>
    </Router>
  );
}

function App() {
  const [data, setData] = useState(null); // Initialize with null
  
  
  useEffect(() => {
    // Make an API call to check if the user is authenticated.
    // Replace this with your actual API call logic.
    fetch(process.env.REACT_APP_API_URL + 'api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert('POST request failed');
        }
      })
      .then((data) => {
        if (data) {
          setData(data); // Update the data state
          console.log(data)
        }
      })
      .catch((error) => {
        //console.error('Error:', error);
      });
  }, []); // Empty dependency array



  if (data === null) {
    return <Success />;
  }
  
  return data.user=='Partner' ? <DashboardRoutes data={data.data} /> : <LandingRoutes data={data.data}/>;

}

export default App;



