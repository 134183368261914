import React, { useState } from 'react';
import { Dashboard } from '../../structure/structure';
import { HedingSubheding, MainHeading } from '../../../component/headings/heading';
import CustomDropdown from '../form-component/formcomponents';
import { FormGroup } from './order-form';
import { ButtonSecondary } from '../../dash_buttons/buttons';
import { ButtonPrimary } from '../../../component/button/button';
import ProductGrid from '../../../component/product/product-grid/product-grid';
import Popup from '../../../component/popup/popup';
import { B2BOrdersummry } from '../../checkout/orderSummryB2B';

export const OrderSample = (props) => {
  if (!props.data.isnew) {
    window.location = "/orders";
  }

  const gsm_options = ['130 gsm', '170 gsm', '220 gsm', '300 gsm'];
  const paper_options = ['Matt art paper', 'Glossy art paper', 'Lustre paper', 'Metallic Paper', 'Silk Paper'];
  const lamination_options = ['Matt Laminated', 'Glossy Laminated', 'Silk Laminated', 'Feather Laminated'];
  const quantity_options = ['1', '2', '5', '10'];

  const [selectedGSM, setSelectedGSM] = useState('170 gsm');
  const [selectedPaper, setSelectedPaper] = useState('Matt art paper');
  const [selectedLamination, setSelectedLamination] = useState('Matt Laminated');
  const [selectedQuantity, setSelectedQuantity] = useState('1');
  const [address, setAddress] = useState(props.data.addresses.length > 0 ? props.data.addresses[0].street : '');
  const [city, setCity] = useState(props.data.addresses.length > 0 ? props.data.addresses[0].city : '');
  const [zip, setZip] = useState(props.data.addresses.length > 0 ? props.data.addresses[0].postalCode : '');
  const [isChecked, setIsChecked] = useState(false);
  const [orderSummry,setOrderSummry] = useState(false)
  const handleSelectGSM = (selectedValue) => {
    setSelectedGSM(selectedValue);
  };

  const handleSelectPaper = (selectedValue) => {
    setSelectedPaper(selectedValue);
  };

  const handleSelectLamination = (selectedValue) => {
    setSelectedLamination(selectedValue);
  };

  const handleSelectQuantity = (selectedValue) => {
    setSelectedQuantity(selectedValue);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleZipChange = (event) => {
    setZip(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = () => {
    if (!isChecked) {
     setShowPopup(true)
     Set_popup_message("Please agree to the terms and conditions")
      return; // Don't proceed further if the checkbox is not checked
    }
    if (address !== "" && city !== "" && zip !== "") {
      const data = {
        address,
        city,
        zipcode: zip,
        gsm: selectedGSM,
        paper: selectedPaper,
        lamination: selectedLamination,
        quantity: selectedQuantity,
        _id: props.data._id
      };
      setOrderSummry(true)
      return
      const apiUrl = process.env.REACT_APP_API_URL + "api/paymentForSample";

      // Make the fetch request here
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include',
      };

      fetch(apiUrl, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 201) {
            window.location = '/login';
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
          window.location = data.url;
        })
        .catch(error => {
          console.error("Error sending payment ID: " + error);
        });
    } else {
      setShowPopup(true)
     Set_popup_message("Please fill in address details.")
    }
  };

  const [showPopup,setShowPopup] = useState(false);
  const [popup_message,Set_popup_message] = useState('Something went wrong');

  return (
    <Dashboard data={props.data}>
      {orderSummry?<>
      <B2BOrdersummry isSample={true} sample={{id:'655c1182a351e24672af24ec',quanttity:selectedQuantity,data:{
        address,
        city,
        zipcode: zip,
        gsm: selectedGSM,
        paper: selectedPaper,
        lamination: selectedLamination,
        quantity: selectedQuantity,
        plan:'655c1182a351e24672af24ec',
        _id: props.data._id
      }}}/>
      </>:
      <>
      <Popup
        show={showPopup}
        heading={'Alert'}
        message={popup_message}
        isNotification={true}
        onConfirmPopup={(result) => {
          setShowPopup(false);
        }}
      />
      <HedingSubheding className="hide-desktop-f" heading="Physical Sample" sub_heading="A non-customized copy of imurs printed as per your specifications to match your taste." />
      <div className='sample-break' style={{gap:'inherit'}}>
      <img src="physical_sample.jpg" alt="sample_img" style={{ margin: 'auto', maxWidth: '540px',borderRadius:'var(--br-1)' }} />
      <div style={{display:'flex',flexDirection:'column',gap:'inherit'}}>
      <div style={{ display: 'flex', gap: '40px' }}>
        <CustomDropdown
          lable="GSM"
          options={gsm_options}
          selectedValue={selectedGSM}
          onSelect={handleSelectGSM}
          recommend={1}
          dropdownStyle={{}}
          optionStyle={{}}
        />
        <CustomDropdown
          lable="Paper"
          options={paper_options}
          selectedValue={selectedPaper}
          onSelect={handleSelectPaper}
          recommend={0}
          dropdownStyle={{}}
          optionStyle={{}}
        />
      </div>
      <div style={{ display: 'flex', gap: '40px' }}>
        <CustomDropdown
          lable="Lamination"
          options={lamination_options}
          selectedValue={selectedLamination}
          onSelect={handleSelectLamination}
          dropdownStyle={{}}
          recommend={0}
          optionStyle={{}}
        />

        <CustomDropdown
          lable="Quantity"
          options={quantity_options}
          selectedValue={selectedQuantity}
          onSelect={handleSelectQuantity}
          recommend={1}
          dropdownStyle={{}}
          optionStyle={{}}
        />
      </div>
      <hr></hr>
      <div className='form' style={{ margin: '0' }}>
        <FormGroup label="Address" inputType="text" id="address" value={address} onChange={handleAddressChange} />
        <div className='g2' style={{ flexDirection: 'row' }}>
          <FormGroup label="Enter City" inputType="text" id="city" value={city} onChange={handleCityChange} />
          <FormGroup label="Enter Zipcode" inputType="text" id="zip" value={zip} onChange={handleZipChange} />
        </div>
      </div>
      <div className='chackbox-group'>
        <input type="checkbox" id="conf" name="conf" value="conf" style={{ width: '30px' }} checked={isChecked} onChange={handleCheckboxChange} />
        <label htmlFor="conf">
          I understand that sampling charge is a nominal fee and has nothing to do with the actual price of the product.
        </label>
      </div>
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        <div onClick={handleSubmit} style={{ width: 'fit-content', cursor: 'pointer' }}>
          <ButtonPrimary customStyle={{marginLeft:'0px'}} text="Pay ₹299" />
        </div>
      </div>
      </div>
      </div>
      
      

      </>}
    </Dashboard>
  );
};

export const Orderd2csample = (props)=>
{



  console.log(props.data);

  return(

    <div className="body" style={{padding:'20px',paddingBottom:'100px'}}>
    <HedingSubheding heading="Imurs’ Greatest Hits" sub_heading="Expressions of Love, illustrated life’s milestones, passport pages and many more"/>
    <ProductGrid info={props.data} flag={1} />
  </div>
  )





}