import React from "react";
import Marquee from "react-fast-marquee";
import "./marquee.css"
import { EditionsMarque } from "./top/topmarquee";
import { HedingSubheding } from "../headings/heading";
import { TypeAnimation } from 'react-type-animation';
import { ButtonUnderLine } from "../button/button";
import { NavLink } from "react-router-dom";

export const InfiniteMarquee = () => (
  <Marquee className="marquee-cont" direction="right">
    <div className="marquee">
      <div className="text-wrapper">Beyond Images</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Preserving Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Home Decor</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Investment in Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Guaranteed Returns</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Immaterialistic Gifting</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Beyond Images</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Preserving Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Home Decor</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Investment in Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Guaranteed Returns</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Immaterialistic Gifting</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Beyond Images</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Preserving Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Home Decor</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Investment in Memories</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Guaranteed Returns</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper">Immaterialistic Gifting</div>
      <img className="star" alt="Star" src="Marquee-Star.svg" />
    </div>
    
  </Marquee>
  
);







export const InfiniteIconMarquee = (props) => {
  const bullets = props.info;
  console.log(bullets)

  const infoTexts = [];
  const keys = Object.keys(bullets);
  const maxIndex = keys.length; // Define your maximum index, e.g., 35
  
  for (let i = 1; i <= maxIndex; i++) {
      const text = bullets[`text${i}`]; // Access info.text1, info.text2, ..., info.textX
    console.log('hello',text)

      if (text) {
        infoTexts.push(i+"^"+text);
      } 
  }
  console.log(infoTexts)

  const displayElements = infoTexts.map((text, index) => (
    <div className="marquee" key={index}>
      <div className="text-wrapper">{text.split("^")[1]}</div>
      <img className="star" alt="Star" src={`product_icons/${text.split("^")[0]}.svg`} />
    </div>
  ));
  
  return (
    <Marquee className="marquee-cont-icon" direction="left" speed={30}> 
      {displayElements}
      {displayElements}
      {displayElements}
    </Marquee>
  );
  
  }  

  export const ColabrationMarquee = () => {
    // Create an array of numbers from 1 to 10
    const imageNumbers = Array.from({ length: 10 }, (_, index) => index + 1);
  
    return (
      <Marquee  gradient={true} gradientColor={'var(--isabeline)'} gradientWidth={'70px'}> 
        <div className="colab-marquee">
          {imageNumbers.map((number) => (
            <img
              key={number}
              className="colab-logo"
              src={`partnersmarquelogos/${number}.png`}
              alt="icon"
            />
          ))}
        </div>
      </Marquee>
    );
  };

  export const RegistrationMarquee = ()=>
{
return(
  <Marquee className="marquee-cont1" direction="right" speed={30}>
    <div className="marquee">
      <div className="text-wrapper1">Beyond Images</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Stronger brand value</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Blend with memories</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Home Decor</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Beyond Images</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Stronger brand value</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Blend with memories</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Home Decor</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div> <div className="marquee">
      <div className="text-wrapper1">Beyond Images</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Stronger brand value</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Blend with memories</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Home Decor</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div> <div className="marquee">
      <div className="text-wrapper1">Beyond Images</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Stronger brand value</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Blend with memories</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Home Decor</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div> <div className="marquee">
      <div className="text-wrapper1">Beyond Images</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Stronger brand value</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Blend with memories</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
    <div className="marquee">
      <div className="text-wrapper1">Home Decor</div>
      <img className="star" style={{filter:'brightness(0%'}}  alt="Star" src="Marquee-Star.svg" />
    </div>
  </Marquee>)

};

export const WhiteMarquee = (props)=>
{
return(
  <Marquee className="marquee-cont1" style={props.style} direction="left" speed={30}>
    {props.words.map((word, index) => (
        <div className="marquee" key={index}>
          <div className="text-wrapper1">{word}</div>
          <img className="star" style={{ filter: 'brightness(0%)' }} alt="Star" src="/Marquee-Star.svg" />
        </div>
      ))
    }
    {props.words.map((word, index) => (
        <div className="marquee" key={index}>
          <div className="text-wrapper1">{word}</div>
          <img className="star" style={{ filter: 'brightness(0%)' }} alt="Star" src="/Marquee-Star.svg" />
        </div>
      ))}
      {props.words.map((word, index) => (
        <div className="marquee" key={index}>
          <div className="text-wrapper1">{word}</div>
          <img className="star" style={{ filter: 'brightness(0%)' }} alt="Star" src="/Marquee-Star.svg" />
        </div>
      ))}

    
  </Marquee>)

};


export const WelcomMarquee = ()=>{
  let typer = <>
  <span>Do you love </span>
  <TypeAnimation
  sequence={[
    'creating',
    2000, 
    'preserving',
    2000,
  ]}
  wrapper="span"
  speed={1}
  style={{ display: 'inline-block',fontSize:'inherit',color:'var(--jet-black-4)',fontWeight:'300',fontFamily:'HK Grotesk-Light' }}
  repeat={Infinity}
/><br></br>
<spna>memories?</spna>
  </>
  return (
    <>
      <div className="img-marque-txt-cont">
        <HedingSubheding
          heading={typer}
          subStyle={{ width: "70%" }}
          sub_heading="Life's a blank canvas, and your memories are the paint. Don't settle for digital snapshots, preserve every moment of your life, meaningfully."
        />
        <NavLink to={"/plans"}>
          <ButtonUnderLine text="Create Order"></ButtonUnderLine>
        </NavLink>
      </div>

      <Marquee style={{ overflow: "visible", marginTop: "-20px" }} speed={40}>
        <EditionsMarque
          img="/welcomMarquee/hm1.png"
          cat="Birthday Spotlight"
          link="/selectPlan?category=6590402d60c1efcf9794a318&categoryname=Gifting&subcategoryname=birthday%20spotlight"
          sub="A shower to their life from toddler moments to adulthood"
        />
        <EditionsMarque
          img="/welcomMarquee/hm2.png"
          cat="Wedding Tears and Tiaras"
          link="/selectPlan?category=6529948df4750b49143ab145&categoryname=Couple&subcategoryname=wedding%20tears%20%26%20tiaras"
          sub="A timeless keepsake of your wedding day with the tears of joy."
        />
        <EditionsMarque
          img="/welcomMarquee/hm3.png"
          cat="High Fives and Diplomas"
          link="/selectPlan?category=651c1a5031d23ba73f1b88c6&categoryname=Self&subcategoryname=high%20fives%20%26%20diplomas"
          sub="A toast to convocation gown, friendships and late-night laughs"
        />
        <EditionsMarque
          img="/welcomMarquee/hm4.png"
          cat="Celebrating Mom and Dad's Love"
          link="/selectPlan?category=651c1a5031d23ba73f1b88c6&categoryname=Self&subcategoryname=high%20fives%20%26%20diplomas"
          sub={`A celebration of moments that make their bond so true`}
        />
        <EditionsMarque
          img="/welcomMarquee/hm5.png"
          cat="Milestones and Farewell"
          link="/selectPlan?category=6551fecf2c7193abf6109213&categoryname=Gifting&subcategoryname=milestones%20%26%20farewells"
          sub='A toast to the next adventure & whispering a warm "thank you"'
        />
        <EditionsMarque
          img="/welcomMarquee/hm6.png"
          cat="Courtship Whispers and Vows "
          link="/selectPlan?category=655201be2c7193abf6109262&categoryname=Couple&subcategoryname=courtship%20whispers%20%26%20vows"
          sub="Whispering the secret nervous dance of falling in love"
        />
        <EditionsMarque
          img="/welcomMarquee/hm7.png"
          cat="Every Reason and one Proposal"
          link="/selectPlan?category=65903e4460c1efcf9794a308&categoryname=Couple&subcategoryname=every%20reason%20%26%20one%20proposal"
          sub='A love sonnet whispering every reason of why "us'
        />
        <EditionsMarque
          img="/welcomMarquee/hm8.png"
          cat="Reunited and Reminiscing"
          link="/selectPlan?category=65a69e9c4b9dc0ed545c3697&categoryname=Couple&subcategoryname=Reunited%26Reminiscing"
          sub="Rekindling the warmth of old memories and friends"
        />
        <EditionsMarque
          img="/welcomMarquee/hm9.png"
          cat="Pre-Wedding Palettes"
          link="/selectPlan?category=652995d3f4750b49143ab149&categoryname=Couple&subcategoryname=pre-wedding%20palettes"
          sub="The excitement, anxieties and those day dreams of 'life after wedding'"
        />
        <EditionsMarque
          img="/welcomMarquee/hm10.png"
          cat="Bridging Hearts and Miles"
          link="/selectPlan?category=651c652feda65a3745e93930&categoryname=Couple&subcategoryname=bridging%20hearts%20%26%20miles"
          sub='Long distance lovers living a dream that fueled every "I miss you”'
        />
      </Marquee>
    </>
  );
}

export const NotFounf = ()=>{
  return(
    <>
    <HedingSubheding heading={'404 - Page Not Found'} sub_heading={'It look like this link is broken or is out of service. Please recheck the link for any typo.'}/>
    </>
  )
}