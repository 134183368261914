import { NavLink } from "react-router-dom";
import "./landingdash.css"
import { useLocation } from 'react-router-dom';
export const LandingSideNavigation = () => {
    return (
      <div className="side-navigation">
        <NavLink to="/" className="headding" >imurs</NavLink>
        <NavLink exact to='/' className='button'>
          <img className="text-no" alt='logo' src='/logos/landing_dash/home.svg'/>
          <span className='text'>Home</span>
        </NavLink>
        <NavLink exact to='/explore' className='button'>
          <img className="text-no" alt='logo' src='/logos/landing_dash/explore.svg'/>
          <span className='text'>Explore</span>
          {/* <div className="notify-dot"></div> */}
        </NavLink>
        <NavLink exact to='/orders' className='button'>
          <img className="text-no" alt='logo' src='/logos/landing_dash/orders.svg'/>
          <span className='text'>Orders</span>
        </NavLink>
        
        <NavLink exact to='/samples' className='button'>
          <img className="text-no" alt='logo' src='/logos/web-stories.svg'/>
          <span className='text'>Samples</span>
        </NavLink>
        <NavLink exact to='/account' className='button'>
          <img className="text-no" alt='logo' src='/logos/landing_dash/profile.svg'/>
          <span className='text '>Profile</span>
        </NavLink>
      </div>
    );
  }

export const LandingDash = ({children}) =>{
  const location = useLocation();

  // Check if the current route is the pricing page
  const isPricingPage = location.pathname.startsWith('/pricing') || location.pathname.startsWith('/abstract') || location.pathname.startsWith('/tracking') || location.pathname.startsWith('/edition') || location.pathname.startsWith('/Partnerlogin');

    return(
        <div className="landingDash">
            <div className="landingMain">
            {children}

            </div>
            {!isPricingPage && <LandingSideNavigation />}
        </div>
    )
}